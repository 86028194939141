import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";

import { authActions } from "../../contexts/authSlice";

const NetworkError = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    const closeHandler = (e) => {
        dispatch(authActions.setnetworkError({ networkError: false }));
    };

    navigator?.connection?.addEventListener('change', (e) => {
        if (e.currentTarget.rtt !== 0) {
            closeHandler()
        }
    })

    return (
        <Wrapper>
            <Content>
                <div className="left">
                    <img
                        src={
                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/networkError.svg"
                        }
                        alt=""
                    />
                </div>
                <div className="middle">
                    <h2>You’re offline</h2>
                    <p>
                        Sorry! there was a problem with your internet
                        connection.
                    </p>
                </div>
                <div className="right">
                    <img
                        src={
                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/closeBtn.svg"
                        }
                        alt=""
                        onClick={closeHandler}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default NetworkError;

const Wrapper = styled.section`
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* background-color: rgb(133 133 133 / 25%); */
`;

const fadeIn = keyframes`
    0%{
        scale: 0.7;
        opacity: 0.7;
    }
    100%{
        scale: 1;
        opacity: 1;
    }
`;

const Content = styled.div`
    padding: 24px;
    max-width: 95%;
    border-radius: 6px;
    animation: ${fadeIn} 0.3s ease-in-out;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 32px;
    box-shadow: 4px 4px 26px rgba(0, 0, 0, 0.06);
    border-left: 5px solid #ff5a4f;

    .left {
        img {
            width: 36px;
        }
    }
    .middle {
        h2 {
            font-family: gordita_medium;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #0a0a0a;
            margin-bottom: 8px;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #747474;
        }
    }
    .right {
        img {
            width: 24px;
            cursor: pointer;
        }
    }

    @media all and (max-width: 480px) {
        gap: 16px;
        padding: 16px;

        .left {
            img {
                width: 26px;
            }
        }
        .middle {
            h2 {
                font-size: 14px;
            }
            p {
                font-size: 12px;
            }
        }
    }
`;
