import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import styled from "styled-components";
import LoginModal from "../includes/modals/LoginModal";

function Spotlight() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const navigate = useNavigate();

    const [isLoginModal, setLoginModal] = useState(false);

    return (
        <>
            {isLoginModal && (
                <LoginModal
                    isLoginModal={isLoginModal}
                    setLoginModal={setLoginModal}
                />
            )}
            <MainContainer>
                <WrapperBox className="wrapper">
                    <ContentBox>
                        <LeftContainer>
                            <MainHeading>
                                <GreentText>Jobified </GreentText>
                                Introducing Job Oriented Programme for{" "}
                                <GreentText>First Year</GreentText> College
                                Students
                            </MainHeading>
                            <MiddleContainer>
                                {/* <MiddleBox>
                                    <ApplyButton
                                        onClick={() => {
                                            if (isAuthenticated) {
                                                navigate("/dashboard/");
                                            } else {
                                                setLoginModal(true);
                                            }
                                        }}
                                    >
                                        Apply Now
                                    </ApplyButton>
                                    <ApplyArrowBox>
                                        <ApplyArrow
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/apply-arrow.svg"
                                            }
                                            alt="apply-arrow"
                                        />
                                    </ApplyArrowBox>
                                </MiddleBox> */}
                                <LogoContainer>
                                    <SideHeading>
                                        In Association With{" "}
                                        <UnderlineBox>
                                            <UnderlineImage
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/green_underline.svg"
                                                }
                                                alt="apply-arrow"
                                            />
                                        </UnderlineBox>
                                    </SideHeading>
                                    {/* <CompanyLogoBox>
                                        <img
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/steyp-tegain.svg"
                                            }
                                            alt="steyp-tegain"
                                        />
                                    </CompanyLogoBox> */}
                                    <CompanyLogoBox>
                                        <a
                                            href="https://steyp.com/"
                                            target="_blank"
                                        >
                                            <LogoBox>
                                                <LogoImage
                                                    src={
                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/steyp.svg"
                                                    }
                                                    alt="logo"
                                                />
                                            </LogoBox>
                                        </a>

                                        <a
                                            href="https://tegain.com/"
                                            target="_blank"
                                        >
                                            <LogoBox>
                                                <LogoImage
                                                    src={
                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/tegain-logo.svg"
                                                    }
                                                    alt="logo"
                                                />
                                            </LogoBox>
                                        </a>
                                    </CompanyLogoBox>
                                </LogoContainer>
                            </MiddleContainer>
                        </LeftContainer>
                        <RightContainer>
                            <SpotlightImageBox>
                                <SpotlightImage
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/spotlight.png"
                                    }
                                    alt="spotlight-image"
                                />
                            </SpotlightImageBox>
                        </RightContainer>
                    </ContentBox>
                </WrapperBox>
            </MainContainer>
        </>
    );
}

export default Spotlight;

const MainContainer = styled.section`
    padding-top: 90px;
    background: linear-gradient(72.75deg, #ebf7fa -5.54%, #fefdfc 100%);
`;
const WrapperBox = styled.section`
    padding-bottom: 100px;
    @media all and (max-width: 980px) {
        padding-bottom: 80px;
    }
    @media all and (max-width: 640px) {
        padding-bottom: 70px;
    }
    @media all and (max-width: 480px) {
        padding-bottom: 45px;
    }
`;
const ContentBox = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-direction: column;
        justify-content: center;
    }
`;
const LeftContainer = styled.div`
    width: 48%;
    // padding: 110px 0 0 0;

    @media all and (max-width: 1400px) {
        padding-right: 35px;
    }
    @media all and (max-width: 1280px) {
        padding-top: 100px;
        padding-right: 0;
    }
    @media all and (max-width: 1080px) {
        padding-top: 98px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        padding-top: 65px;
        text-align: center;
    }
    @media all and (max-width: 768px) {
        padding-top: 45px;
    }
    @media all and (max-width: 640px) {
        padding-top: 30px;
    }
    @media all and (max-width: 480px) {
    }
`;

const MainHeading = styled.h1`
    font-size: 48px;
    font-family: "gordita_medium";
    color: #19244e;
    line-height: 1.5em;
    margin-bottom: 10px;
    @media all and (max-width: 1650px) {
        font-size: 48px;
        margin-bottom: 10px;
    }
    @media all and (max-width: 1600px) {
        font-size: 48px;
    }
    @media all and (max-width: 1460px) {
        font-size: 48px;
    }

    @media all and (max-width: 1400px) {
        font-size: 46px;
    }
    @media all and (max-width: 1300px) {
        font-size: 42px;
        margin-bottom: 0;
    }
    @media all and (max-width: 1100px) {
        font-size: 35px;
    }
    @media all and (max-width: 1080px) {
        font-size: 32px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 50px;
        font-size: 43px;
    }
    @media all and (max-width: 920px) {
        margin-bottom: 40px;
    }
    @media all and (max-width: 820px) {
        font-size: 40px;
        margin-bottom: 25px;
    }
    @media all and (max-width: 768px) {
        font-size: 32px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 30px;
        font-size: 28px;
    }
    @media all and (max-width: 540px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        margin-bottom: 15px;
        font-size: 28px;
    }
    @media all and (max-width: 360px) {
        font-size: 24px;
    }
`;
const GreentText = styled.span`
    color: #32bcad;
    font-size: 52px;
    &:first-child {
        font-family: "gordita_bold";
        margin-right: 12px;
    }
    &:last-child {
        font-family: "gordita_medium";
    }
    @media all and (max-width: 1650px) {
        font-size: 48px;
    }
    @media all and (max-width: 1600px) {
        font-size: 48px;
    }
    @media all and (max-width: 1460px) {
        font-size: 48px;
    }
    @media all and (max-width: 1400px) {
        font-size: 46px;
    }
    @media all and (max-width: 1300px) {
        font-size: 42px;
    }
    @media all and (max-width: 1100px) {
        font-size: 35px;
    }
    @media all and (max-width: 1080px) {
        font-size: 32px;
    }
    @media all and (max-width: 980px) {
        font-size: 43px;
    }
    @media all and (max-width: 820px) {
        font-size: 40px;
    }
    @media all and (max-width: 768px) {
        font-size: 32px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 28px;
    }
    @media all and (max-width: 360px) {
        font-size: 22px;
    }
`;
const MiddleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 40px;
    @media all and (max-width: 980px) {
        flex-direction: row-reverse;
        justify-content: center;
        justify-content: space-between;
    }
    @media all and (max-width: 900px) {
        flex-direction: column-reverse;
        align-items: center;
    }
`;
const MiddleBox = styled.div`
    display: flex;
    align-items: center;
    @media all and (max-width: 900px) {
        margin-top: 25px;
    }
`;
const ApplyArrowBox = styled.div`
    margin-left: 35px;
    padding-bottom: 70px;
    @media all and (max-width: 1080px) {
        width: 75px;
        padding-bottom: 40px;
    }
    @media all and (max-width: 768px) {
        padding-bottom: 0px;
    }
`;
const ApplyArrow = styled.img``;
const ApplyButton = styled(Link)`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    font-size: 14px;
    width: 174px;
    height: 60px;
    display: flex;
    color: #fff;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-family: "gordita_medium";

    @media all and (max-width: 1280px) {
        font-size: 17px;
    }
    @media all and (max-width: 1080px) {
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
        padding: 12px 24px;
        margin-top: 35px;
    }
    @media all and (max-width: 640px) {
        margin-top: 20px;
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        margin-top: 25px;
        width: 130px;
        height: 42px;
        font-size: 13px;
    }
`;
const LogoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    padding: 20px;
    border: 0.776401px solid #0a3333;
    border-radius: 10px;
    width: max-content;
    margin-top: 30px;

    @media all and (max-width: 1280px) {
        /* width: 65%; */
    }
    @media all and (max-width: 1080px) {
        /* width: 69%; */
    }
    @media all and (max-width: 980px) {
        /* width: 65%; */
        margin: 0 auto;
    }
    @media all and (max-width: 900px) {
        /* width: 54%; */
        padding: 18px;
    }
    @media all and (max-width: 850px) {
        /* width: 59%; */
    }
    @media all and (max-width: 768px) {
        /* width: 61%; */
    }
    @media all and (max-width: 640px) {
        /* width: 85%; */
        padding: 15px;
    }
    @media all and (max-width: 550px) {
        /* width: 79%; */
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const SideHeading = styled.h2`
    position: relative;
    display: inline-block;
    color: #0a0a0a;
    font-family: "gordita_medium";
    font-size: 22px;
    @media all and (max-width: 1400px) {
        font-size: 18px;
    }
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    @media all and (max-width: 540px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const CompanyLogoBox = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 12px 32px;
    background-color: #fff;
    border-radius: 5px;
    max-height: 70px;
    justify-content: space-between;
    box-sizing: border-box;

    gap: 32px;
    @media all and (max-width: 1800px) {
    }
    @media all and (max-width: 1700px) {
    }
    @media all and (max-width: 1600px) {
    }
    @media all and (max-width: 1500px) {
    }
    @media all and (max-width: 1200px) {
    }
    @media all and (max-width: 900px) {
        /* width: 48%; */
    }
    @media all and (max-width: 680px) {
    }
    @media all and (max-width: 580px) {
    }
    @media all and (max-width: 480px) {
    }
`;
const LogoBox = styled.div`
    cursor: pointer;
    /* margin-right: 30px; */
    /* height: 55px; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    &:last-child {
        margin-right: 0;
    }
    @media all and (max-width: 1700px) {
        margin-right: 20px;
    }
    @media all and (max-width: 1650px) {
        /* height: 45px; */
        width: 70px;
    }
    @media all and (max-width: 1500px) {
        width: 60px;
    }
    @media all and (max-width: 1400px) {
        margin-right: 12px;
    }
    @media all and (max-width: 1300px) {
        height: 40px;
        width: 60px;
    }
    @media all and (max-width: 980px) {
        /* height: 45px; */
    }
    @media all and (max-width: 900px) {
        width: 70px;
    }
`;
const LogoImage = styled.img`
    height: 100%;
`;
const UnderlineBox = styled.div`
    width: 167px;
    position: absolute;
    right: 0px;
    bottom: -9px;
    @media all and (max-width: 1280px) {
        width: 147px;
    }
    @media all and (max-width: 1080px) {
        width: 147px;
    }
    @media all and (max-width: 980px) {
        width: 147px;
    }
`;
const UnderlineImage = styled.img``;
const RightContainer = styled.div`
    position: relative;
    width: 50%;
    padding-top: 60px;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const SpotlightImageBox = styled.div`
    @media all and (max-width: 980px) {
        width: 90%;
        margin: 0 auto;
    }
`;
const SpotlightImage = styled.img``;
