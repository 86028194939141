// default react imports 
import React from "react";
// 3rd party package imports 
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./components/routing/routers/MainRouter";
import { useDispatch, useSelector } from "react-redux";
// local imports 
import "./assets/css/slider.css";
import "./assets/css/style.css";
import NetworkError from "./components/includes/modals/NetworkError";
import { authActions } from "./components/contexts/authSlice";


function App() {
    // hooks 
    const dispatch = useDispatch()
    // global state 
    const isNetworkError = useSelector(state => state.auth.networkError)

    // to detect internet connection goes off and dispatch newtWorkError toast 
    navigator?.connection?.addEventListener('change', (e) => {
        if (e.currentTarget.rtt === 0) {
            dispatch(authActions.setnetworkError({ networkError: true }));
        }
    })

    return (
        <>
            {isNetworkError && <NetworkError />}
            <Router>
                <MainRouter />
            </Router>
        </>
    );
}

export default App;
