import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isAuthenticated: localStorage.getItem('isAuthenticated') ? localStorage.getItem('isAuthenticated') : false,
    accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null,
    refreshToken: localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null,
    phone: localStorage.getItem('phone') ? localStorage.getItem('phone') : null,
    name: localStorage.getItem('name') ? localStorage.getItem('name') : null,
    lastName: localStorage.getItem('last_name') ? localStorage.getItem('last_name') : null,
    campusId: localStorage.getItem('campusId') ? localStorage.getItem('campusId') : null,
    networkError: false,
}


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            const { phone, access, refresh, isAuthenticated, name, campusId,lastName } = action.payload
            const newState = {
                isAuthenticated,
                phone,
                accessToken: access,
                refreshToken: refresh,
                name,
                campusId,
                lastName
            }

            localStorage.setItem('isAuthenticated', isAuthenticated)
            localStorage.setItem('phone', phone)
            localStorage.setItem('accessToken', access)
            localStorage.setItem('refreshToken', refresh)
            localStorage.setItem('name', name)
            localStorage.setItem('last_name', lastName)
            localStorage.setItem('campusId', campusId)

            return newState
        },
        logout: (state, action) => {
            localStorage.clear()
            state.isAuthenticated = false
        },
        updateUserData: (state, action) => {
            state.name = action.payload.name
            state.campusId = action.payload.campusId
            state.lastName = action.payload.lastName

            localStorage.setItem('name', action.payload.name)
            localStorage.setItem('last_name', action.payload.lastName)
            localStorage.setItem('campusId', action.payload.campusId)
        },
        setnetworkError: (state, { payload }) => {
            state.networkError = payload.networkError
        }
    }
})

export default authSlice.reducer

export const authActions = authSlice.actions