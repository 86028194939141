import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import youtube from "../../assets/images/youtube.svg";
import linkedin from "../../assets/images/linkedin-gray.svg";

function Footer() {
    const [id, setId] = useState();

    const passEachId = (id) => {
        setId(id);
    };
    const icons = [
        {
            id: 0,
            logo: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/instagram.svg",
            path: "http://instagram.com/jobified.ai ",
        },
        {
            id: 1,
            logo: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/facebook.svg",
            path: "https://www.facebook.com/profile.php?id=100088411636216&mibextid=ZbWKwL",
        },
        {
            id: 2,
            logo: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/twitter.svg",
            path: "https://twitter.com/Jobified_ai",
        },
        {
            id: 3,
            logo: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/linkedin-gray.svg",
            path: "https://www.linkedin.com/company/jobified-ai/",
        },
        {
            id: 4,
            logo: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/youtube.svg",
            path: "https://www.youtube.com/@jobified",
        },
    ];

    return (
        <MainContainer className="contact">
            <WrapperBox>
                <LogoBox smooth={true} spy={true} to="home">
                    <LogoImage
                        src={
                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/jobified-white.svg"
                        }
                        alt="logo"
                    />
                </LogoBox>
                <NavBox>
                    <NavItem
                        activeClass="active"
                        to="home"
                        smooth={true}
                        spy={true}
                    >
                        Home
                    </NavItem>
                    <NavItem
                        activeClass="active"
                        to="about"
                        smooth={true}
                        spy={true}
                    >
                        About
                    </NavItem>
                    <NavItem
                        activeClass="active"
                        to="jobs"
                        smooth={true}
                        spy={true}
                    >
                        Jobs
                    </NavItem>
                    <NavItem
                        activeClass="active"
                        to="contact"
                        smooth={true}
                        spy={true}
                    >
                        Contact Us
                    </NavItem>
                </NavBox>
                <SocialMediaContainer>
                    {icons.map((item) => (
                        <LogoContainer
                            key={item.id}
                            target="_blank"
                            href={item.path}
                            onMouseOver={() => {
                                passEachId(item.id);
                            }}
                            onMouseLeave={() => setId(null)}
                        >
                            <Logo
                                className={id === item.id ? "active" : ""}
                                src={item.logo}
                                alt="icon"
                            />
                        </LogoContainer>
                    ))}
                </SocialMediaContainer>

                <BottomBox>
                    <CreditDetails>
                        © {new Date().getFullYear()}, Jobified. All rights
                        reserved.
                    </CreditDetails>
                </BottomBox>
            </WrapperBox>
        </MainContainer>
    );
}

export default Footer;

const MainContainer = styled.section`
    background-color: #000f09;
`;
const WrapperBox = styled.section`
    width: 80%;
    margin: 0 auto;
    padding-top: 100px;
    @media all and (max-width: 980px) {
        padding-top: 80px;
    }
    @media all and (max-width: 640px) {
        padding-top: 70px;
    }
    @media all and (max-width: 480px) {
        padding-top: 45x;
    }
`;
const LogoBox = styled(Link)`
    display: flex;
    justify-content: center;
    width: 220px;
    margin: 0 auto;
    cursor: pointer;
    margin-bottom: 35px;
    @media all and (max-width: 1580px) {
        width: 200px;
    }
    @media all and (max-width: 480px) {
        width: 110px;
        margin-bottom: 20px;
    }
`;
const LogoImage = styled.img``;
const NavBox = styled.div`
    width: 36%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    @media all and (max-width: 1530px) {
        width: 38%;
    }
    @media all and (max-width: 1280px) {
        width: 49%;
    }
    @media all and (max-width: 1080px) {
        width: 61%;
    }
    @media all and (max-width: 980px) {
        width: 55%;
    }
    @media all and (max-width: 860px) {
        width: 59%;
    }
    @media all and (max-width: 720px) {
        width: 74%;
    }
    @media all and (max-width: 640px) {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 25px;
    }
    @media all and (max-width: 360px) {
        flex-wrap: wrap;
    }
`;
const NavItem = styled(Link)`
    cursor: pointer;
    margin-right: 20px;
    font-size: 24px;
    color: #eeeeee;
    font-family: "gordita_medium";
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        color: #32bcad;
    }
    // &.active {
    //     color: #32bcad;
    // }
    @media all and (max-width: 980px) {
        font-size: 22px;
    }
    @media all and (max-width: 860px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 17px;
        margin-right: 15px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const SocialMediaContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    @media all and (max-width: 360px) {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
    }
`;
const LogoContainer = styled.a`
    width: 48px;
    cursor: pointer;
    margin-right: 25px;
    border: 1px solid #d4d4d4;
    padding: 8px;
    border-radius: 50%;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background-color: #32bcad;
    }
    @media all and (max-width: 480px) {
        width: 35px;
        margin-right: 15px;
    }
    @media all and (max-width: 360px) {
        margin-bottom: 12px;
        margin-right: 0;
    }
`;
const Logo = styled.img`
    &.active {
        filter: brightness(1.5);
    }
`;
const Description = styled.p`
    text-align: center;
    color: #d4d4d4;
    width: 46%;
    margin: 0 auto;
    @media all and (max-width: 1650px) {
        width: 48%;
    }
    @media all and (max-width: 1580px) {
        width: 75%;
    }
    @media all and (max-width: 1280px) {
        width: 80%;
    }
    @media all and (max-width: 1080px) {
        width: 90%;
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        font-size: 16px;
    }
`;
const BottomBox = styled.div`
    padding: 40px 0 80px;
    margin-top: 30px;
    border-top: 1px solid #aaa;
    @media all and (max-width: 480px) {
        padding-bottom: 40px;
    }
    @media all and (max-width: 480px) {
        padding-top: 30px;
        margin-top: 20px;
    }
`;
const CreditDetails = styled.p`
    text-align: center;
    font-size: 18px;
    color: #d4d4d4;
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
