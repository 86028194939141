import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Topbar from "../../includes/dashboard/Topbar";
import Sidebar from "../../includes/dashboard/Sidebar";
import Contents from "../../screens/dashboard/Contents";
import { Suspense } from "react";
import SectionLoader from "../../helpers/SectionLoader";
import OrientationVideos from "../../screens/dashboard/OrientationVideos";

function AppRouter() {
    const [isMenu, setMenu] = useState(false);

    return (
        <Container>
            <Topbar isMenu={isMenu} setMenu={setMenu} />
            <Sidebar isMenu={isMenu} />
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    <Route path="dashboard/" element={<Contents />} />
                    <Route
                        path="orientation-videos/"
                        element={<OrientationVideos />}
                    />
                </Routes>
            </Suspense>
        </Container>
    );
}

export default AppRouter;
const Container = styled.div`
    min-height: 100vh;
    position: relative;
`;