import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import useOutsideClick from "../../../hooks/useOutsideClick";

const ContactUs = ({ handler, modalTop }) => {
    const button = document.getElementById("contact-us-button");
    const _handler = () => {
        handler();
    };

    const modalRef = useOutsideClick(_handler, button);
    // useEffect(() => {
    //     window.scrollTo({
    //         top: modalRef.current.offsetTop,
    //         behavior: "smooth",
    //     });
    // }, []);
    return (
        <Wrapper ref={modalRef} id="contact" top={modalTop}>
            <h1>Hi there 👋🏻</h1>
            <p>
                Connect the college's placement officer with Jobified if not
                already associated
            </p>
            <a href="tel:+918589999555">+91 858 999 9555</a> <br />
            <a href="tel:+918589998066">+91 858 999 8066</a>
        </Wrapper>
    );
};

export default ContactUs;
const fadeIn = keyframes`
    0%{
        opacity: 0.7;
        scale: 0.8;
    }
    100%{
        opacity: 1;
        scale: 1;
    }
`;
const Wrapper = styled.section`
    position: absolute;
    width: 389px;
    max-width: 90vw;
    z-index: 5;
    top: ${({ top }) => (top !== 0 ? `${top}px` : "-20px")};
    padding: 40px;
    background: linear-gradient(94.55deg, #0d1637 -24.65%, #54ebda 145.52%);
    border-radius: 12px;
    animation: ${fadeIn} 0.3s ease-in-out;
    @media all and (max-width: 480px) {
        padding: 20px;
    }

    h1 {
        font-family: gordita_regular;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 31px;
        color: #fff;
    }
    p {
        font-family: gordita_regular;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #fff;
        margin: 24px 0 16px;
        @media all and (max-width: 480px) {
            font-size: 16px;
        }
    }
    a {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        @media all and (max-width: 480px) {
            font-size: 16px;
        }
    }

    @media all and (max-width: 360px) {
        h1 {
            font-size: 18px;
        }
        p {
            font-size: 16px;
        }
        a {
            font-size: 16px;
        }
    }
`;
