import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import EditProfile from "../modals/EditProfile";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../contexts/authSlice";
import useOutsideClick from "../../hooks/useOutsideClick";
import Avatar from "react-avatar";

function Topbar({ isMenu, setMenu }) {
    // open modal
    const [open, setOpen] = useState(false);
    // open editprofilemodal
    const [editProfileisOpen, setEditProfileIsOpen] = useState(false);

    const username = useSelector((state) => state.auth.name);
    const lastName = useSelector((state) => state.auth.lastName);

    const handler = () => {
        setOpen(false);
    };

    return (
        <>
            <Container>
                <Content>
                    <Left>
                        <LogoContainer>
                            <Link to="/">
                                <img
                                    src={require("../../../assets/images/dashboard/jobified.png")}
                                    alt="logo"
                                />
                            </Link>
                        </LogoContainer>
                        <MenuContainer onClick={() => setMenu(!isMenu)}>
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/menu-icon.svg"
                                }
                                alt="menu"
                            />
                        </MenuContainer>
                    </Left>
                    <Right>
                        {/* <SearchContainer>
                            <SearchBar>
                                <SearchSection>
                                    <SearchIcon
                                        src={
                                            require("../../../assets/images//dashboard/Search.svg")
                                                .default
                                        }
                                        alt="search-icon"
                                    />
                                </SearchSection>
                                <SearchBox
                                    type="text"
                                    placeholder="Search...
                                />
                            </SearchBar>
                        </SearchContainer> */}
                        <ProfileContainer>
                            {/* <LeftContainer>
                                <MsgSection>
                                    <img
                                        src={
                                            require("../../../assets/images/dashboard/message.svg")
                                                .default
                                        }
                                        alt="message"
                                    />
                                </MsgSection>
                                <NotificationSection>
                                    <img
                                        src={
                                            require("../../../assets/images/dashboard/notification.svg")
                                                .default
                                        }
                                        alt="notification"
                                    />
                                </NotificationSection>
                            </LeftContainer> */}
                            <RightContainer
                                id="account-action"
                                onClick={(e) => setOpen(!open)}
                            >
                                <Avatar
                                    name={`${username[0]} ${lastName[0]}`}
                                    size="50"
                                    round="50%"
                                />
                                <Name>{`${username.split(" ")[0]} ${lastName.split(" ")[0]}`}</Name>
                                <DropDownContainer
                                    onClick={() => setOpen(!open)}
                                >
                                    <img
                                        src={
                                            require("../../../assets/images/dashboard/down.svg")
                                                .default
                                        }
                                        alt="Drop down"
                                    />
                                </DropDownContainer>
                                {open && (
                                    <AccountActionModal
                                        handler={handler}
                                        editprofileHandler={
                                            setEditProfileIsOpen
                                        }
                                    />
                                )}
                            </RightContainer>
                        </ProfileContainer>
                    </Right>
                </Content>
            </Container>
            {editProfileisOpen && (
                <EditProfile close={() => setEditProfileIsOpen(false)} />
            )}
        </>
    );
}

const AccountActionModal = ({ handler, editprofileHandler }) => {
    const dispatch = useDispatch();

    const parentRef = document.getElementById("account-action");
    const menuRef = useOutsideClick(handler, parentRef);
    const navigate = useNavigate();

    return (
        <DropFunction ref={menuRef}>
            <LogOut onClick={(e) => editprofileHandler(true)}>
                <ImgContainer>
                    <img
                        src={
                            require("../../../assets/images/dashboard/user-edit.svg")
                                .default
                        }
                        alt="Edit"
                    />
                </ImgContainer>
                <Text>Edit Profile</Text>
            </LogOut>
            <LogOut
                onClick={() => {
                    dispatch(authActions.logout());
                    navigate("/");
                }}
            >
                <ImgContainer>
                    <img
                        src={
                            require("../../../assets/images/dashboard/logout.svg")
                                .default
                        }
                        alt="Logout"
                    />
                </ImgContainer>
                <Text className="logout">Logout</Text>
            </LogOut>
        </DropFunction>
    );
};

export default Topbar;
const Container = styled.section`
    background: #f8f8f8;
    padding: 13px;
    position: fixed;
    width: 100%;
    z-index: 110;
`;
const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Left = styled.div`
    width: 260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: -21px;
        width: 1px;
        background-color: #fff;
        height: 77px;
        @media all and (max-width: 480px) {
            display: none;
        }
    }
    @media all and (max-width: 1080px) {
        width: 216px;
    }
    @media all and (max-width: 980px) {
        width: 192px;
    }
    @media all and (max-width: 768px) {
        width: 190px;
    }
    @media all and (max-width: 480px) {
        width: 165px;
    }
    @media all and (max-width: 360px) {
        width: 140px;
    }
`;
const Right = styled.div`
    padding: 0 10px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media all and (max-width: 1280px) {
        width: 72%;
    }
    @media all and (max-width: 1080px) {
        width: 73%;
    }
    @media all and (max-width: 980px) {
        width: 69%;
        padding: 0;
    }
    @media all and (max-width: 768px) {
        display: flex;
        width: 66%;
        justify-content: flex-end;
    }
    @media all and (max-width: 640px) {
        width: 53%;
    }
    @media all and (max-width: 480px) {
        width: 31%;
    }
`;
const LogoContainer = styled.div`
    width: 130px;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        width: 112px;
    }
    @media all and (max-width: 980px) {
        width: 98px;
    }
    @media all and (max-width: 768px) {
        width: 82px;
    }
`;
const MenuContainer = styled.div`
    display: none;
    width: 25px;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        display: block;
    }
    @media all and (max-width: 480px) {
        width: 20px;
    }
`;
const SearchContainer = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media all and (max-width: 768px) {
        display: none;
    }
`;
const SearchSection = styled.div`
    width: 20px;
    margin-right: 5px;
`;
const SearchBar = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #f4f4f4;
    padding: 8px 20px;
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 93%;
        padding: 6px 20px;
    }
`;
const SearchIcon = styled.img`
    width: 20px;
`;
const SearchBox = styled.input``;
const ProfileContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 1280px) {
        width: 100%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const LeftContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        height: 46px;
        width: 2px;
        border-radius: 10px;
        background-color: #737171;
        right: -15px;
        @media all and (max-width: 1280px) {
            height: 36px;
        }
        @media all and (max-width: 980px) {
            height: 28px;
        }
    }
    @media all and (max-width: 980px) {
        width: 16%;
    }
    @media all and (max-width: 768px) {
        display: none;
    }
`;
const RightContainer = styled.div`
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media all and (max-width: 1280px) {
        width: 28%;
    }
    @media all and (max-width: 980px) {
        width: 36%;
    }
    @media all and (max-width: 768px) {
        width: 45%;
    }
    @media all and (max-width: 640px) {
        width: 62%;
    }
    @media all and (max-width: 480px) {
        width: 46%;
    }
`;
const ProfileImage = styled.div`
    width: 50px;
    margin-right: 15px;
    @media all and (max-width: 1280px) {
        width: 40px;
    }
    @media all and (max-width: 980px) {
        width: 35px;
    }
    @media all and (max-width: 360px) {
        width: 36px;
    }
`;
const Name = styled.h4`
    font-family: gordita_medium;
    cursor: pointer;
    color: #344049;
    font-size: 17px;
    margin-right: 15px;
    margin-left: 10px;
    @media all and (max-width: 1280px) {
        margin-right: 0;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const DropDownContainer = styled.div`
    width: 10px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        margin-left: 10px;
    }
`;
const MsgSection = styled.div`
    cursor: pointer;
    width: 20px;
    @media all and (max-width: 980px) {
        width: 16px;
    }
`;
const NotificationSection = styled.div`
    cursor: pointer;
    width: 20px;
    @media all and (max-width: 980px) {
        width: 16px;
    }
`;

const DropFunction = styled.div`
    width: 250px;
    position: absolute;
    padding: 30px 26px;
    z-index: 100;
    border-radius: 3px;
    top: 65px;
    right: 0px;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
    @media all and (max-width: 640px) {
        width: 170px;
    }
`;

const LogOut = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid #adadad;
    padding-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
        padding-top: 15px;
        padding-bottom: 0;
    }
    @media all and (max-width: 640px) {
        margin-top: 6px;
    }
`;
const ImgContainer = styled.div`
    width: 20px;
    margin-right: 10px;
    svg {
        fill: #e02b1d;
    }
`;
const Text = styled.p`
    text-align: center;
    color: #747474;
    font-size: 16px;
    font-family: "gordita_regular";

    &.logout {
        color: #e02b1d;
    }
`;
