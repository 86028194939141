import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import FailModal from "../../includes/modals/FailModal";
import OnlineSuccessModal from "../../includes/modals/OnlineSuccessModal";
import ResultModal from "../../includes/modals/ResultModal";
import ScheduleInterviewModal from "../../includes/modals/ScheduleInterviewModal";
import SuccessModal from "../../includes/modals/SuccessModal";
// import ThankYouModal from "../../includes/modals/ThankYouModal";
import timer from "../../../assets/images/dashboard/timer.svg";
import tickIcon from "../../../assets/images/dashboard/tick1.png";
import $ from "jquery";
// import authApi from "../../../axiosConfig";
import { useDispatch } from "react-redux";
import { authActions } from "../../contexts/authSlice";
import useAuthApi from "../../hooks/useApi";
import Sidebar from "../../includes/dashboard/Sidebar";
import moment from "moment";
// import useHandleScroll from "../../hooks/useHandleScroll";

function Contents() {
    const [isResult, setResult] = useState(false);
    // const [isFail, setFail] = useState(false);
    // const [isAttending, setAttending] = useState(false);
    const [interview, setInterview] = useState(false);
    const [isOnline, setSucessOnline] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    // interview time scheduling
    const [interviewTime, setInterviewtime] = useState("");
    const [interviewDate, setInterviewDate] = useState("");
    const [offlineTime, setOfflineTime] = useState();
    const [interviewDetails, setDetails] = useState({
        link: null,
        location: null,
        mode: null,
    })

    // orientaton video
    const [isOriented, setIsOriented] = useState(false);
    const [isInterviewScheduled, setIsInterviewScheduled] = useState(false);
    const [isPublished, setIsPublished] = useState(false); // interview result published or not

    const dispatch = useDispatch();
    const authApi = useAuthApi();

    const timeList = [
        {
            title: "09:00 AM  -  10:00 AM",
            time: "09:00:00",
        },
        {
            title: "10:00 AM  -  11:00 AM",
            time: "10:00:00",
        },
        {
            title: "11:00 AM  -  12:00 PM",
            time: "11:00:00",
        },
        {
            title: "12:00 PM  -  01:00 PM",
            time: "12:00:00",
        },
        {
            title: "01:00 PM  -  02:00 PM",
            time: "01:00:00",
        },
        {
            title: "02:00 PM  -  03:00 PM",
            time: "02:00:00",
        },
        {
            title: "03:00 PM  -  04:00 PM",
            time: "03:00:00",
        },
        {
            title: "04:00 PM  -  05:00 PM",
            time: "04:00:00",
        },
        {
            title: "05:00 PM  -  06:00 PM",
            time: "05:00:00",
        },
        {
            title: "06:00 PM  -  07:00 PM",
            time: "06:00:00",
        },
    ];

    // avoid outside scroll
    function handleScroll() {
        if (
            interview ||
            isResult ||
            isOnline ||
            isSuccess
        ) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }

    console.log(isSuccess, "===isSuccess===")

    const fetchDashboard = () => {
        authApi
            .get("/users/dashboard/")
            .then((response) => {
                const { statusCode, data } = response.data;
                console.log(response.data);

                if (statusCode === 6000) {
                    setIsInterviewScheduled(data.is_interview_scheduled);
                    setIsOriented(data.is_oriented);
                    setIsPublished(data.is_published);
                    setDetails({
                        link: data.interview_link,
                        location: data.interview_location,
                        mode: data.interview_mode,
                    })
                    setOfflineTime(data.interview_time)
                    setInterviewDate(data.interview_date)
                }
            })
            .catch((error) => {
                console.log(error.message);
                if (error.response.status === 401) {
                    dispatch(authActions.logout());
                }
            });
    };

    useEffect(() => {
        fetchDashboard();
    }, []);

    useEffect(() => {
        handleScroll();
    }, [interview, isResult, isOnline, isSuccess]);

    console.log(interviewDetails, "interviewDetails");
    console.log(offlineTime, "offlineTime");
    return (
        <>
            {isResult && (
                <ResultModal
                    isResult={isResult}
                    setResult={setResult}
                    student_result="pending"
                />
            )}
            {/* {isMenu && <Sidebar setMenu={setMenu} isMenu={isMenu} />} */}
            {/* <FailModal isFail={isFail} setFail={setFail} />
            <ThankYouModal
                isAttending={isAttending}
                setAttending={setAttending}
            /> */}
            {isOnline && (
                <OnlineSuccessModal
                    isOnline={isOnline}
                    setSucessOnline={setSucessOnline}
                    interview={interview}
                    setInterview={setInterview}
                    setInterviewtime={setInterviewtime}
                    setInterviewDate={setInterviewDate}
                    interviewTime={interviewTime}
                    interviewDate={interviewDate}
                    offlineTime={offlineTime}
                    timeList={timeList}
                />
            )}

            {isSuccess && (
                <SuccessModal
                    isSuccess={isSuccess}
                    setSuccess={setSuccess}
                    interview={interview}
                    setInterview={setInterview}
                    setInterviewtime={setInterviewtime}
                    setInterviewDate={setInterviewDate}
                    interviewTime={interviewTime}
                    interviewDate={interviewDate}
                    offlineTime={offlineTime}
                    timeList={timeList}
                />
            )}

            {interview && (
                <ScheduleInterviewModal
                    interview={interview}
                    setInterview={setInterview}
                    isSuccess={isSuccess}
                    setSuccess={setSuccess}
                    isOnline={isOnline}
                    setSucessOnline={setSucessOnline}
                    setIsInterviewScheduled={setIsInterviewScheduled}
                    setOfflineTime={setOfflineTime}
                    timeList={timeList}
                    setDetails={setDetails}
                    setInterviewDate={setInterviewDate}
                />
            )}

            <Container>
                <Section id="main">
                    <Cover
                    // onClick={() => setMenu(!isMenu)}
                    >
                        <Top>
                            <Heading>Complete Your Application</Heading>
                            <Description>Follow the steps below</Description>
                        </Top>

                        <ListItems>
                            <Items>
                                <Left>
                                    <span>01</span>
                                </Left>
                                <Right>
                                    <CoverBox>
                                        <Div>
                                            <ImageContainer>
                                                <img
                                                    src={
                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Object.png"
                                                    }
                                                    alt="object"
                                                />
                                            </ImageContainer>
                                        </Div>
                                        <Details>
                                            <Title>
                                                Watch the orientation video
                                            </Title>
                                            <Paragraph>
                                                Pellentesque suscipit fringilla
                                                libero eu ullamcorper. Cras
                                                risus eros, faucibus sit amet
                                                augue id,
                                            </Paragraph>
                                            <Button to="/orientation-videos">
                                                Watch Now
                                                <img
                                                    src={
                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/arrow-right.svg"
                                                    }
                                                    alt="arrow"
                                                />
                                            </Button>
                                        </Details>
                                        <Timer>
                                            <img
                                                src={
                                                    isOriented
                                                        ? tickIcon
                                                        : timer
                                                }
                                                alt="timer"
                                            />
                                        </Timer>
                                    </CoverBox>
                                </Right>
                            </Items>
                            <Items>
                                <Left className="second">
                                    <span>02</span>
                                </Left>
                                <Right>
                                    <CoverBox>
                                        <Div>
                                            <ImageContainer className="interview">
                                                <img
                                                    src={
                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/interview.png"
                                                    }
                                                    alt="object"
                                                />
                                            </ImageContainer>
                                        </Div>
                                        <Details>
                                            <Title>Schedule Interview</Title>
                                            {isOriented && isInterviewScheduled ? (
                                                <>
                                                    {interviewDetails.mode === "online" ? (
                                                        <OnlineDetails>
                                                            Your Interview has been scheduled online on &nbsp;
                                                            <span>
                                                                {moment(interviewDate).format("DD-MM-YYYY")}
                                                            </span>{" "}
                                                            Via <span>Online Meet</span> at{" "}
                                                            <span>
                                                                {timeList.map((time) => time.time === offlineTime && time.title)}
                                                            </span>, Check your{" "}
                                                            mail for more details.
                                                        </OnlineDetails>
                                                    ) : (
                                                        <OnlineDetails>
                                                            Your walk-in interview has been scheduled at &nbsp;
                                                            <span>{interviewDetails?.location?.name}</span>{" "}
                                                            on{" "}
                                                            <span>
                                                                {moment(interviewDate).format("DD-MM-YYYY")}
                                                            </span>{" "}in between{" "}
                                                            <span>
                                                                {timeList.map((time) => time.time === offlineTime && time.title)}
                                                            </span>
                                                        </OnlineDetails>
                                                    )}
                                                </>
                                            ) : (
                                                <Paragraph>
                                                    Pellentesque suscipit fringilla
                                                    libero eu ullamcorper. Cras
                                                    risus eros, faucibus sit amet
                                                    augue id,
                                                </Paragraph>
                                            )}
                                            {!isInterviewScheduled && isOriented && (

                                                <Button
                                                    className={[
                                                        isInterviewScheduled
                                                            ? "scheduled"
                                                            : "",
                                                        isOriented
                                                            ? "active"
                                                            : "interview disabled",
                                                    ]}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        !isInterviewScheduled &&
                                                            isOriented &&
                                                            setInterview(
                                                                !interview
                                                            );
                                                    }}
                                                >
                                                    Schedule Now
                                                    <img
                                                        src={
                                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/arrow-right.svg"
                                                        }
                                                        alt="arrow"
                                                    />
                                                </Button>
                                            )}
                                        </Details>
                                        <Timer>
                                            <img
                                                src={
                                                    isInterviewScheduled
                                                        ? tickIcon
                                                        : timer
                                                }
                                                alt="timer"
                                            />
                                        </Timer>
                                    </CoverBox>
                                </Right>
                            </Items>
                            <Items>
                                <Left className="third">
                                    <span>03</span>
                                </Left>
                                <Right>
                                    <CoverBox>
                                        <Div>
                                            <ImageContainer>
                                                <img
                                                    src={
                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/result.png"
                                                    }
                                                    alt="result"
                                                />
                                            </ImageContainer>
                                        </Div>
                                        <Details>
                                            <Title>Result</Title>
                                            <Paragraph>
                                                Pellentesque suscipit fringilla
                                                libero eu ullamcorper. Cras
                                                risus eros, faucibus sit amet
                                                augue id,
                                            </Paragraph>
                                            <Button
                                                className={[
                                                    isOriented &&
                                                        isInterviewScheduled
                                                        ? "active"
                                                        : "disabled interview",
                                                ]}
                                                onClick={() =>
                                                    isOriented &&
                                                        isInterviewScheduled
                                                        ? setResult(!isResult)
                                                        : ""
                                                }
                                            >
                                                View Result
                                                <img
                                                    src={
                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/arrow-right.svg"
                                                    }
                                                    alt="arrow"
                                                />
                                            </Button>
                                        </Details>
                                        <Timer>
                                            <img
                                                src={
                                                    isPublished
                                                        ? tickIcon
                                                        : timer
                                                }
                                                alt="timer"
                                            />
                                        </Timer>
                                    </CoverBox>
                                </Right>
                            </Items>
                        </ListItems>
                    </Cover>
                </Section>
            </Container>
        </>
    );
}

export default Contents;
const Container = styled.section`
    min-height: 100vh;
`;

const OnlineDetails = styled.div`
    color: #747474;
    font-size: 14px;
    width: 65%;
    margin-top: 16px;
    font-family: gordita_regular;
    margin-bottom: 20px;
    /* text-align: center; */
    @media all and (max-width: 1280px) {
        width: 75%;
    }
    @media all and (max-width: 1080px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 98%;
    }
    @media all and (max-width: 640px) {
        font-size: 13px;
        width: 100%;
    }
    span {
        color: #009262;
        font-size: 14px;
        font-family: gordita_medium;
    }
`


const Section = styled.section``;
const Cover = styled.div`
    padding: 31px 76px 20px;
    @media all and (max-width: 1280px) {
        padding: 31px 56px 20px;
    }
    @media all and (max-width: 1080px) {
        padding: 31px 40px 20px;
    }
    @media all and (max-width: 980px) {
        padding: 31px 22px 20px;
    }
    @media all and (max-width: 768px) {
        padding-left: 10px;
    }
`;
const Top = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const Heading = styled.h2`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 24px;
    margin-bottom: 15px;
    @media all and (max-width: 1280px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 21px;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 9px;
    }
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    color: #747474;
    font-size: 16px;
    font-family: gordita_regular;
    @media all and (max-width: 980px) {
        font-size: 15px;
    }
`;
const ListItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const Items = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* margin-bottom: 25px; */

    &:last-child {
        margin-bottom: 20px;
    }
`;
const Left = styled.div`
    background: #30b5a7;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 44px;
    height: 44px;

    position: relative;

    @media all and (max-width: 980px) {
        width: 33px;
        height: 33px;
    }
    &.third {
        ::after {
            display: none;
        }
    }
    &.second {
        ::after {
            @media all and (max-width: 1880px) {
                height: 228px;
                /* min-height: 272px; */
            }
            @media all and (max-width: 1746px) {
                height: 212px;
            }
            @media all and (max-width: 1220px) {
                height: 18px;
            }
            @media all and (max-width: 480px) {
                height: 272px;
                min-height: 272px;
            }
            @media all and (max-width: 360px) {
                height: 262px;
                min-height: 262px;
            }
        }
    }
    span {
        font-size: 16px;
        font-family: "gordita_medium";
        color: #fff;
        @media all and (max-width: 980px) {
            font-size: 12px;
        }
    }
    ::after {
        content: "";
        width: 10px;
        height: 240px;
        border-right: 2px dashed #30b5a7;
        position: absolute;
        top: 44px;
        left: 11px;
        min-height: 203px;
        @media all and (max-width: 1880px) {
            height: 237px;
            min-height: 208px;
        }
        @media all and (max-width: 1600px) {
            height: 200px;
            min-height: 208px;
        }
        @media all and (max-width: 1280px) {
            height: 232px;
            min-height: 232px;
        }
        @media all and (max-width: 1080px) {
            height: 204px;
            min-height: 204px;
        }
        @media all and (max-width: 980px) {
            top: 36px;
            left: 5px;
            height: 170px;
            min-height: 170px;
        }
        @media all and (max-width: 768px) {
            height: 172px;
            min-height: 172px;
        }
        @media all and (max-width: 480px) {
            height: 264px;
            min-height: 264px;
        }
        @media all and (max-width: 360px) {
            height: 253px;
            min-height: 253px;
        }
    }
   
`;
const Right = styled.div`
    background: #f0f9f9;
    width: 94%;
    border-radius: 15px;
    padding: 30px;
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media all and (max-width: 1280px) {
        width: 92%;
        padding: 25px 20px;
    }
    @media all and (max-width: 980px) {
        width: 90%;
        padding: 11px 15px;
    }
    @media all and (max-width: 768px) {
        width: 88%;
    }
    @media all and (max-width: 480px) {
        width: 86%;
    }
`;
const ImageContainer = styled.div`
    @media all and (max-width: 480px) {
        width: 30%;
    }
    &.interview {
        margin: 0 15px;
        @media all and (max-width: 980px) {
            width: 90%;
            margin: 0;
        }
        @media all and (max-width: 480px) {
            width: 30%;
        }
    }

    img {
        width: 100%;
        display: block;
    }
`;
const Time = styled.div`
    display: none;

    @media all and (max-width: 480px) {
        display: block;
        width: 30px;
    }
`;
const Details = styled.div`
    width: 80%;
    @media all and (max-width: 1280px) {
        width: 76%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Title = styled.h4`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 20px;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
    @media all and (max-width: 1080px) {
        font-size: 17px;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
`;
const Paragraph = styled.p`
    font-size: 14px;
    margin-top: 10px;
    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
`;
const Button = styled(Link)`
    margin-top: 20px;

    width: 135px;
    height: 42px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 9px;
    font-size: 13px;
    color: #fff;
    width: 160px;

    &.disabled {
        cursor: not-allowed;
    }
    &.active {
        background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
        cursor: pointer;
    }
    &.scheduled {
        cursor: not-allowed;
    }
    &.interview {
        background: #adadad;
        width: 159px;
        height: 42px;
        @media all and (max-width: 1280px) {
            width: 145px;
        }
        @media all and (max-width: 768px) {
            width: 137px;
            font-size: 12px;
        }
    }

    img {
        margin-left: 8px;
        width: 25px;
        display: block;
    }
    @media all and (max-width: 1280px) {
        font-size: 13px;
        width: 158px;
    }

    @media all and (max-width: 980px) {
        width: 151px;
        font-size: 12px;
    }
`;
const Timer = styled.div`
    /* width: 5%; */
    @media all and (max-width: 1280px) {
        /* width: 6%; */
    }

    @media all and (max-width: 480px) {
        display: none;
    }

    img {
        display: block;
        width: 100%;
    }
`;
const CoverBox = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const Div = styled.div`
    width: 15%;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1280px) {
        width: 21%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
