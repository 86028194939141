import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import Bg from "../../../assets/images/dashboard/modal-background.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import button_loader from "../../../assets/lotties/button_loader.json";
import Lottie from "react-lottie";
import useOutsideClick from "../../hooks/useOutsideClick";
import useAuthApi from "../../hooks/useApi";

function ScheduleInterviewModal({
    interview,
    setInterview,
    isOnline,
    setSucessOnline,
    setSuccess,
    isSuccess,
    setIsInterviewScheduled,
    setOfflineTime,
    timeList,
    setDetails,
    setInterviewDate
}) {
    const calenderRef = useRef();
    const authApi = useAuthApi();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    // date picker
    let minDate = new window.Date();
    const [date, setStartDate] = useState('');

    const [mode, setMode] = useState("online");
    // locations
    const [place, setPlace] = useState([]);
    const [locationModal, setLocationModal] = useState(false);
    const [isLocationSelected, setisSelected] = useState(false);
    const [location, setLocation] = useState({
        name: "Choose location",
        opening_time: "10:00",
        closing_time: "04:00",
    });

    const [timeModal, setTimeModal] = useState(false);
    const [time, setTime] = useState({
        title: "Choose time",
        time: "00:00:00",
    });

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: button_loader,
        rendererSettings: {},
    };

    useEffect(() => {
        authApi
            .get("/interviews/locations/")
            .then((response) => {
                const { data, statusCode } = response.data;
                if (statusCode === 6000) {
                    setPlace(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage(false);
        }, 5000);
    }, [errorMessage]);

    const submitHandler = () => {
        if (
            time.time !== "00:00:00" &&
            (isLocationSelected || mode === "online") && date
        ) {
            setIsLoading(true);
            const data = {
                date: moment(date).format("YYYY-MM-DD"),
                time: time.time,
                mode,
            };
            if (mode === "offline") {
                data["interview_location"] = location.id;
            }
            authApi
                .post("/interviews/schedule/", data)
                .then((response) => {
                    const { data, statusCode } = response.data;
                    if (statusCode === 6000) {
                        setInterview(false);
                        let tempData = data

                        console.log(data.time,"interview schedule time");

                        // timeList.forEach((time) => {
                        //     if (time.time === tempData.time) {
                        //     }
                        // })
                        setOfflineTime(tempData.time)
                        setInterviewDate(tempData.date)
                        if (mode === "offline") {
                            setSuccess(tempData);

                            setIsInterviewScheduled(true);
                        } else {
                            setSucessOnline(tempData);
                            setIsInterviewScheduled(true);
                        }
                        setDetails({
                            location: {
                                name:data.location,
                            },
                            mode: data.mode,
                        })
                    } else {
                        setErrorMessage(data.message);
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        } else {
            if (time.time === "00:00:00") {
                setErrorMessage("Please choose your time ");
            } else if (!date) {
                setErrorMessage("Please select the date")
            } else if (!isLocationSelected) {
                setErrorMessage("Please select the location");
            }
        }
    };

    const parentRef = document.getElementById("location-parent");
    const locationRef = useOutsideClick(() => {
        setLocationModal(false);
    }, parentRef);

    const timeParentRef = document.getElementById("location-time-parent");
    const timeRef = useOutsideClick(() => {
        setTimeModal(false);
    }, timeParentRef);

    return (
        <>
            {interview && (
                <>
                    <Container>
                        <Overlay
                            onClick={() => setInterview(!interview)}
                        ></Overlay>
                        <Section>
                            <TopSection>
                                <CloseContainer
                                    onClick={() => setInterview(false)}
                                >
                                    <img
                                        src={
                                            require("../../../assets/images/dashboard/close.svg")
                                                .default
                                        }
                                        alt="close"
                                    />
                                </CloseContainer>
                                <LeftBox>
                                    <Title>Schedule Interview </Title>
                                    <Description>
                                        An interview is a structured
                                        conversation where one participant asks
                                        questions, and the other provides
                                        answers.
                                    </Description>
                                </LeftBox>
                                <RightBox>
                                    <ImgContainer>
                                        <img
                                            src={require("../../../assets/images/dashboard/interview-time.png")}
                                            alt="interview-time"
                                        />
                                    </ImgContainer>
                                </RightBox>
                            </TopSection>
                            <MiddleBox>
                                <FormContainer>
                                    <Label>Date</Label>
                                    <Date
                                        onClick={(e) => {
                                            if (
                                                !calenderRef.current.state.open
                                            ) {
                                                calenderRef.current.setOpen(
                                                    true
                                                );
                                            }
                                        }}
                                    >
                                        <img
                                            src={
                                                require("../../../assets/images/dashboard/calendar.svg")
                                                    .default
                                            }
                                            alt="calender"
                                        />
                                        <CalenderBox>
                                            <DatePicker
                                                ref={calenderRef}
                                                selected={date}
                                                onChange={(date) =>
                                                    setStartDate(date)
                                                }
                                                dateFormat={"dd/MM/yyyy"}
                                                placeholderText={"DD / MM / YY"}
                                                minDate={minDate}
                                            />
                                        </CalenderBox>
                                        {/* <DropDown className="date">
                                            <img
                                                src={
                                                    require("../../../assets/images/dashboard/Vector.svg")
                                                        .default
                                                }
                                                alt="arrow"
                                            />
                                        </DropDown> */}
                                    </Date>
                                </FormContainer>
                                <FormContainer>
                                    <Label>Time</Label>
                                    <Time
                                        id="location-time-parent"
                                        onClick={(e) =>
                                            setTimeModal(!timeModal)
                                        }
                                    >
                                        <TimeRightContainer>
                                            <Img
                                                className="time"
                                                src={
                                                    require("../../../assets/images/dashboard/clock.svg")
                                                        .default
                                                }
                                                alt="calender"
                                            />
                                            <span>{time.title}</span>
                                        </TimeRightContainer>

                                        <DropDown>
                                            <img
                                                src={
                                                    require("../../../assets/images/dashboard/Vector.svg")
                                                        .default
                                                }
                                                alt="arrow"
                                            />
                                        </DropDown>
                                    </Time>
                                    {timeModal && (
                                        <TimeDropdownWrapper ref={timeRef}>
                                            <TimeDropDown>
                                                {timeList.map((time, index) => (
                                                    <TimeList
                                                        key={index}
                                                        onClick={(e) => {
                                                            setTime(time);
                                                            setTimeModal(false);
                                                        }}
                                                    >
                                                        {time.title}
                                                    </TimeList>
                                                ))}
                                            </TimeDropDown>
                                        </TimeDropdownWrapper>
                                    )}
                                </FormContainer>
                                <FormContainer className="modes">
                                    <Label>Mode</Label>
                                    <Modes>
                                        <Online
                                            onClick={() => setMode("online")}
                                        >
                                            <OuterCircle>
                                                <InnerCircle
                                                    className={
                                                        mode === "online"
                                                            ? "active"
                                                            : ""
                                                    }
                                                ></InnerCircle>
                                            </OuterCircle>
                                            Online
                                        </Online>
                                        <Offline
                                            onClick={() => setMode("offline")}
                                        >
                                            <OuterCircle>
                                                <InnerCircle
                                                    className={
                                                        mode === "offline"
                                                            ? "active"
                                                            : ""
                                                    }
                                                ></InnerCircle>
                                            </OuterCircle>
                                            Offline
                                        </Offline>
                                    </Modes>
                                </FormContainer>
                                {mode === "online" ? (
                                    ""
                                ) : (
                                    <FormContainer className="location">
                                        <Label>Location</Label>
                                        <Location
                                            id="location-parent"
                                            onClick={() =>
                                                setLocationModal(!locationModal)
                                            }
                                            className={location.name === "Choose location" ? "active" : ""}
                                        >
                                            {location.name}
                                            <DropDown>
                                                <img
                                                    src={
                                                        require("../../../assets/images/dashboard/Vector.svg")
                                                            .default
                                                    }
                                                    alt="arrow"
                                                />
                                            </DropDown>
                                        </Location>
                                        <LocationBox
                                            type={locationModal}
                                            ref={locationRef}
                                        >
                                            {place.map((item) => (
                                                <List
                                                    key={item.id}
                                                    onClick={() => {
                                                        setLocation(item);
                                                        setisSelected(true);
                                                        setLocationModal(
                                                            !locationModal
                                                        );
                                                    }}
                                                >
                                                    {item.name}
                                                </List>
                                            ))}
                                        </LocationBox>
                                    </FormContainer>
                                )}
                            </MiddleBox>
                            <BottomWrapper>
                                {errorMessage && (
                                    <p className="error">{errorMessage}</p>
                                )}
                                <Submit onClick={submitHandler}>
                                    {!isLoading ? (
                                        "Submit"
                                    ) : (
                                        <Lottie
                                            options={lottieOptions}
                                            width={28}
                                            height={28}
                                        />
                                    )}
                                </Submit>
                            </BottomWrapper>
                        </Section>
                    </Container>
                </>
            )}
        </>
    );
}

export default ScheduleInterviewModal;

const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 111;
    left: 0;
    top: 0;
    animation-name: ${videoAnimation};
    animation-duration: 0.5s;
    transition: all 0.5s ease-in-out;
`;

const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(10px);
    cursor: pointer;
`;
const Section = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    background-color: #fff;
    width: 50%;
    max-height: 90vh;
    /* overflow-y: scroll; */
    padding: 40px;
    transition: all 0.6s ease-in-out;

    *{
        font-family: gordita_medium;
    }

    @media all and (max-width: 1280px) {
        width: 70%;
        padding: 30px;
    }
    @media all and (max-width: 1080px) {
    }
    @media all and (max-width: 980px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 88%;
    }
    @media all and (max-width: 640px) {
        width: 90%;
        padding: 24px;
    }
    @media all and (max-width: 480px) {
        padding: 13px;
        width: 93%;
    }
    @media all and (max-width: 360px) {
    }
    @media all and (max-width: 320px) {
    }
`;
const LocationBox = styled.div`
    display: ${({ type }) => (type ? "block" : "none")};
    border: 1px solid #adadad;
    z-index: 1000;
    max-height: 100px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -70px;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;
const List = styled.div`
    text-transform: capitalize;
    color: #000;
    font-family: gordita_regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;

    :hover {
        background-color: rgb(240 255 244);
    }
`;
const CloseContainer = styled.div`
    position: absolute;
    right: -55px;
    top: -55px;
    width: 40px;
    cursor: pointer;

    @media all and (max-width: 1280px) {
        right: -46px;
        top: -42px;
    }
    @media all and (max-width: 980px) {
        right: -47px;
        top: -49px;
    }
    @media all and (max-width: 640px) {
        right: -39px;
        top: -38px;
        width: 30px;
    }

    @media all and (max-width: 480px) {
        right: -24px;
        top: -24px;
    }
`;
const TopSection = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding: 28px;
    background: url(${Bg});
    align-items: center;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    @media all and (max-width: 980px) {
        padding: 22px;
    }
    @media all and (max-width: 480px) {
        padding: 15px;
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const LeftBox = styled.div`
    width: 70%;
    margin-right: 40px;
    @media all and (max-width: 980px) {
        width: 74%;
        margin-right: 0px;
    }
    @media all and (max-width: 640px) {
        width: 77%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const Title = styled.h4`
    color: #2d2d2d;
    font-size: 22px;
    font-family: gordita_medium;
    margin-bottom: 20px;
    @media all and (max-width: 980px) {
        font-size: 20px;
    }
    @media all and (max-width: 640px) {
        font-size: 18px;
        margin-bottom: 10px;
    }
    @media all and (max-width: 480px) {
        font-size: 17px;
    }
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const Description = styled.p`
    color: #747474;
    font-size: 16px;
    @media all and (max-width: 980px) {
        font-size: 15px;
    }
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const RightBox = styled.div`
    width: 25%;
    @media all and (max-width: 640px) {
        width: 23%;
    }
    @media all and (max-width: 360px) {
        display: none;
    }
`;
const ImgContainer = styled.div`
    width: 130px;
    @media all and (max-width: 980px) {
        width: 100px;
    }
    @media all and (max-width: 640px) {
        width: 90px;
    }
    @media all and (max-width: 480px) {
        width: 69px;
    }
`;
const MiddleBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-between;
    @media all and (max-width: 1280px) {
        margin-top: 22px;
    }
`;
const FormContainer = styled.div`
    width: 48%;
    position: relative;
    margin-bottom: 30px;
    &.modes {
        width: 100%;
        @media all and (max-width: 1280px) {
        }
    }
    &.location {
        width: 100%;
        position: relative;
        @media all and (max-width: 1280px) {
            margin-bottom: 0;
        }
    }
    @media all and (max-width: 1280px) {
        margin-bottom: 22px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Label = styled.h6`
    color: #747474;
    font-size: 17px;
    font-family: gordita_medium;
    margin-bottom: 10px;
`;
const Date = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    border: 1px solid #adadad;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    @media all and (max-width: 980px) {
        padding: 8px;
    }
    img {
        display: block;
        width: 25px;
        margin-right: 10px;
        @media all and (max-width: 640px) {
            width: 20px;
        }
    }
`;
const CalenderBox = styled.div`
    width: 100%;
    border: none;
    outline: none;
    div.react-datepicker-wrapper > div {
        width: 100% !important;
        border: none !important;
    }
    .react-time-picker {
        box-sizing: border-box;
        border: none !important;
        width: 100% !important;
        display: flex;
        outline: none !important;
    }
    .react-time-picker__button:enabled {
        cursor: pointer;
        display: none !important;
    }
    .react-time-picker__wrapper {
        border: none !important;
    }
    .react-time-picker__inputGroup__input {
        cursor: pointer;
    }
    .react-time-picker__inputGroup__divider {
        margin-right: 6px !important;
    }

    .react-time-picker__clock {
        inset: 37px -11px 13px -6px !important;
    }
    .react-time-picker {
        @media all and (max-width: 640px) {
            height: 28px !important;
        }
    }
`;
const DropDown = styled.div`
    width: 15px;
    cursor: pointer;
    &.date {
        position: absolute;
        right: 8px;
        width: 14px;
    }
    img {
        display: block;
        width: 100%;
    }
    &.time {
        &.time {
            width: 10px;
        }
    }
`;
const Time = styled.div`
    cursor: pointer;
    position: relative;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #adadad;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.react-datepicker-wrapper > div > input {
        width: 100% !important;
    }
`;
const Img = styled.img`
    width: 25px;
    @media all and (max-width: 768px) {
        width: 23px;
        margin-right: 10px;
    }
    @media all and (max-width: 640px) {
        width: 20px;
    }
`;
const Modes = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Online = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    background: #f9f9f9;
    border: 1px solid #adadad;
    border-radius: 8px;
    width: 48%;
    display: flex;
    align-items: center;
    color: #adadad;

    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const OuterCircle = styled.div`
    cursor: pointer;
    border: 1px solid #adadad;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
`;
const InnerCircle = styled.div`
    display: none;
    &.active {
        background: #009262;
        width: 10px;
        height: 10px;
        min-height: 10px;
        min-width: 10px;
        border-radius: 50%;
        display: block;
    }
`;
const Offline = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    background: #f9f9f9;
    color: #adadad;
    border: 1px solid #adadad;
    border-radius: 8px;
    width: 48%;
    display: flex;
    align-items: center;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Location = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    background: #f9f9f9;
    border: 1px solid #adadad;
    border-radius: 8px;
    margin-bottom: 35px;
    width: 100%;

    &.active {
        color: #adadad;
    }

    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;

const Submit = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    color: #fff;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    justify-content: center;
    margin-left: auto;
    font-family: gordita_regular;
    width: 25%;
    @media all and (max-width: 980px) {
        width: 30%;
        padding: 13px 15px;
    }
    @media all and (max-width: 640px) {
        padding: 10px 15px;
        width: 35%;
    }

    @media all and (max-width: 480px) {
        width: 100%;
    }
`;

const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > p.error {
        color: red;
        font-size: 14px;
        margin-bottom: 12px;
    }
`;

const TimeDropDown = styled.ul`
    border: 1px solid #adadad;
    z-index: 1000;
    max-height: 100px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -93px;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;
const TimeList = styled.li`
    text-transform: capitalize;
    color: #000;
    font-family: gordita_regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;

    :hover {
        background-color: rgb(240 255 244);
    }
`;
const TimeDropdownWrapper = styled.section`
    width: 100%;
`;

const TimeRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;

    span {
        font-size: 15px;
    }
`;
