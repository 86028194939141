import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import Decoration from "../../../assets/images/dashboard/decoration.png";
import useAuthApi, { URL } from "../../hooks/useApi";

function ResultModal({ isResult, setResult, student_result }) {
    const authApi = useAuthApi();

    const [status, setStatus] = useState("pending");
    const campusId = useSelector((state) => state.auth.campusId);

    const fetchStatus = () => {
        authApi
            .get("/interviews/status/")
            .then((response) => {
                const { statusCode, data } = response.data;
                if (statusCode === 6000) {
                    setStatus(data.status);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchStatus();
    }, []);

    return (
        <>
            <Container>
                <Overlay onClick={() => setResult(!isResult)}></Overlay>
                {status === "rejected" ? (
                    <Section className="fail">
                        <ImgContainer className="fail">
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/fail.png"
                                }
                                alt="congrats"
                            />
                        </ImgContainer>
                        <Title>Sorry !!</Title>
                        <Description className="fail">
                            You couldn’t make it. Don’t be sad, Better luck next
                            time
                            <br />
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${URL}/interviews/result/list/${campusId}`}
                            >
                                download the list
                            </a>
                        </Description>
                        <Button onClick={() => setResult(!isResult)}>
                            continue
                        </Button>
                    </Section>
                ) : status === "pending" ? (
                    <Section className="waiting">
                        <ImgContainer className="waiting">
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/success.svg"
                                }
                                alt="congrats"
                            />
                        </ImgContainer>
                        <Title>Thank you !!</Title>
                        <Description className="waiting">
                            Thank you for attending the interview.
                            <br /> You’re on list, keep waiting.
                        </Description>
                        <Button onClick={() => setResult(!isResult)}>
                            continue
                        </Button>
                    </Section>
                ) : (
                    <Cover>
                        <Section className="pass">
                            <ImgContainer>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/congrats.svg"
                                    }
                                    alt="congrats"
                                />
                            </ImgContainer>
                            <Title>Congratulation !!!</Title>
                            <Description>
                                You have been selected. Congratulations on your
                                new dream job.
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${URL}/interviews/result/list/${campusId}`}
                                >
                                    download the list{" "}
                                </a>
                            </Description>
                            <Button onClick={() => setResult(false)}>
                                continue
                            </Button>
                        </Section>
                    </Cover>
                )}
            </Container>
        </>
    );
}

export default ResultModal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    z-index: 100000;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
    animation-name: ${videoAnimation};
    animation-duration: 0.3s;
`;
const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(10px);
    cursor: pointer;
`;
const Cover = styled.div`
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 21%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    width: 50%;

    @media all and (max-width: 1280px) {
        width: 55%;
    }
    @media all and (max-width: 980px) {
        width: 65%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 640px) {
        width: 94%;
    }

    @media all and (max-width: 360px) {
        width: 95%;
    }
`;
const Section = styled.div`
    &.pass {
        background: url(${Decoration});
        background-repeat: repeat;
        background-size: cover;
        background-position: unset;
        width: 100%;
        padding: 80px 60px;
        border-radius: 12px;
        margin: 0 auto;
        position: absolute;
        top: 0;
        background-color: #fff;
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: column;

        @media all and (max-width: 640px) {
            padding: 36px;
        }
        @media all and (max-width: 480px) {
            padding: 25px;
        }
        @media all and (max-width: 360px) {
            padding: 20px;
        }
    }
    &.fail {
        width: 50%;
        padding: 80px 60px;
        border-radius: 12px;
        margin: 0 auto;
        position: absolute;
        top: 17%;
        background-color: #fff;
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 1280px) {
            width: 60%;
            top: 14%;
        }
        @media all and (max-width: 980px) {
            width: 70%;
            top: 17%;
        }
        @media all and (max-width: 768px) {
            width: 75%;
        }

        @media all and (max-width: 640px) {
            top: 25%;
            padding: 36px;
            width: 80%;
        }
        @media all and (max-width: 480px) {
            padding: 25px;
            width: 85%;
        }
        @media all and (max-width: 360px) {
            padding: 20px;
        }
    }
    &.waiting {
        width: 50%;
        padding: 80px 60px;
        border-radius: 12px;
        margin: 0 auto;
        position: absolute;
        top: 17%;
        background-color: #fff;
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 1280px) {
            width: 60%;
            top: 14%;
        }
        @media all and (max-width: 980px) {
            width: 70%;
            top: 17%;
        }
        @media all and (max-width: 768px) {
            width: 75%;
        }

        @media all and (max-width: 640px) {
            top: 25%;
            padding: 36px;
            width: 92%;
        }
        @media all and (max-width: 480px) {
            padding: 25px;
            width: 95%;
        }
        @media all and (max-width: 360px) {
            padding: 20px;
        }
    }
`;
const ImgContainer = styled.div`
    width: 24%;
    margin: 0 auto 20px;
    &.fail {
        width: 45%;
        @media all and (max-width: 1280px) {
            width: 50%;
        }
        @media all and (max-width: 980px) {
            width: 55%;
        }
        @media all and (max-width: 768px) {
            width: 60%;
        }

        @media all and (max-width: 640px) {
            width: 65%;
        }
        @media all and (max-width: 480px) {
            width: 80%;
        }
        @media all and (max-width: 360px) {
            width: 80%;
        }
    }
    &.waiting {
        width: 45%;
        @media all and (max-width: 1280px) {
            width: 50%;
        }
        @media all and (max-width: 980px) {
            width: 55%;
        }
        @media all and (max-width: 768px) {
            width: 60%;
        }

        @media all and (max-width: 640px) {
            width: 65%;
        }
        @media all and (max-width: 480px) {
            width: 80%;
        }
        @media all and (max-width: 360px) {
            width: 80%;
        }
    }
    @media all and (max-width: 640px) {
        width: 25%;
    }
    @media all and (max-width: 480px) {
        width: 30%;
    }
`;
const Title = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 20px;
    margin-bottom: 10px;
    @media all and (max-width: 640px) {
        font-size: 18px;
    }
`;
const Description = styled.p`
    color: #747474;
    font-size: 14px;
    width: 65%;
    margin: 0 auto;
    font-family: gordita_regular;
    margin-bottom: 20px;
    text-align: center;
    &.fail {
        width: 40%;
        @media all and (max-width: 1280px) {
            width: 50%;
        }
        @media all and (max-width: 1080px) {
            width: 55%;
        }
        @media all and (max-width: 980px) {
            width: 60%;
        }
        @media all and (max-width: 768px) {
            width: 68%;
        }
        @media all and (max-width: 640px) {
            width: 73%;
        }
        @media all and (max-width: 480px) {
            width: 79%;
        }
        @media all and (max-width: 360px) {
            width: 100%;
        }
    }
    &.waiting {
        width: 70%;
        @media all and (max-width: 1280px) {
            width: 78%;
        }
        @media all and (max-width: 1080px) {
            width: 85%;
        }
        @media all and (max-width: 980px) {
            width: 93%;
        }
        @media all and (max-width: 768px) {
            width: 100%;
        }
        @media all and (max-width: 640px) {
            /* width: 73%; */
        }
        @media all and (max-width: 480px) {
            /* width: 79%; */
        }
        @media all and (max-width: 360px) {
            /* width: 100%; */
        }
    }
    a {
        color: #416bff;
        text-decoration: underline;
    }
    @media all and (max-width: 1280px) {
        width: 75%;
    }
    @media all and (max-width: 1080px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 98%;
    }
    @media all and (max-width: 640px) {
        font-size: 13px;
        width: 70%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
    span {
        color: #009262;
        font-size: 13px;
        font-family: gordita_medium;
    }
`;
const Button = styled.button`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    width: 159px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media all and (max-width: 768px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 17px;
    }
`;
