// default react imports 
import React, { useEffect, useState } from "react";
// 3rd party package imports 
import Lottie from "react-lottie";
import styled, { keyframes } from "styled-components";
import { useDispatch } from "react-redux";
// local imports 
import loaderAnimation from "../../../assets/lotties/loader.json";
import { authActions } from "../../contexts/authSlice";
import useAuthApi, { useApi } from "../../hooks/useApi";
import useOutsideClick from "../../hooks/useOutsideClick";

const EditProfile = ({ close }) => {
    const dispatch = useDispatch();
    const apiConfig = useApi();
    const authApi = useAuthApi();

    // input value state
    const [firstName, setFirstName] = useState("");
    const [oldEmail, setOldEmail] = useState("")
    const [email, setEmail] = useState("");
    const [college, setCollege] = useState({});
    const [semester, setSemester] = useState("Select semester");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [course, setCourse] = useState("");

    const [colleges, setColleges] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    //semester Modal state
    const [showModal, setShowModal] = useState(false);
    const [showCollegeModal, setCollegeModal] = useState(false);
    // error state
    const [errorMessage] = useState("This field is required");
    // const [serverErrorMessage,setServerErrorMessage] = useState('')
    const [firstNameError, setFirstNameError] = useState(false);
    const [emailError, setEmailError] = useState("");
    // const [collegeError, setCollegeError] = useState(false);
    const [semesterError, setSemesterError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [courseError, setCourseError] = useState(false);
    // semesters value
    const semesters = [
        {
            id: 1,
            title: 1,
        },
        {
            id: 2,
            title: 2,
        },
        // {
        //     id: 3,
        //     title: 3,
        // },
        // {
        //     id: 4,
        //     title: 4,
        // },
        // {
        //     id: 5,
        //     title: 5,
        // },
        // {
        //     id: 6,
        //     title: 6,
        // },
        // {
        //     id: 7,
        //     title: 7,
        // },
        // {
        //     id: 8,
        //     title: 8,
        // },
        // {
        //     id: 9,
        //     title: 9,
        // },
        // {
        //     id: 10,
        //     title: 10,
        // },
    ];

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    const validateEmail = () => {
        authApi
            .post('/users/validate-email/', { email })
            .then(response => {
                const { data, statusCode } = response.data

                if (statusCode === 6000) {
                    if (data.is_email_exists) {
                        setEmailError('Email already exists')
                    } else {
                        setEmailError(false)
                    }
                } else {
                    setEmailError(data.message)
                }
            })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (oldEmail !== email && /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(email)) {
                validateEmail()
            }
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [email])

    // phone change handler 
    const onPhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        let value = e.target.value;
        if (value === "" || re.test(value)) {
            setPhoneNumber(e.target.value);
            setPhoneNumberError(false);
        }
        if (value.length <= 0) {
            setPhoneNumberError(true);
        }
    };
    //  enter name
    const onFirstNameChange = (e) => {
        let value = e.target.value;
        setFirstName(value);
        setFirstNameError(false);
        if (value.length <= 0) {
            setFirstNameError(true);
        }
    };

    //  enter email
    const onEmailChange = (e) => {
        let str = e.target.value;

        setEmail(e.target.value);
        if (str) {
            if (
                /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(str) &&
                str.includes(".com")
            ) {
                setEmailError(null);
            } else {
                setEmailError("Enter a valid email");
            }
        } else {
            setEmailError(errorMessage);
        }
    };

    // enter course
    const onCourseChange = (e) => {
        let value = e.target.value;
        setCourse(value);
        setCourseError(false);
        if (!value) {
            setCourseError(true);
        }
    };
    //  enter last name
    const onLastNameChange = (e) => {
        let value = e.target.value;
        setLastName(value);
        setLastNameError(false);
        if (!value) {
            setLastNameError(true);
        }
    };

    const onSemesterChange = () => {
        setSemesterError(false);
    };

    // form validation function  
    const validate = () => {
        if (firstName.trim().length === 0) {
            setFirstNameError(true);
            setFirstName(firstName.trim())
        }
        if (semester.length === 0) {
            setSemesterError(true);
        }
        if (phoneNumber.trim().length === 0) {
            setPhoneNumberError(true);
            setPhoneNumber(phoneNumber.trim())
        }
        if (course.trim().length === 0) {
            setCourseError(true);
            setCourse(course.trim())
        }
        if (lastName.trim().length === 0) {
            setLastNameError(true);
            setLastName(lastName.trim())
        }
        // validate email
        if (
            /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(email) &&
            email.includes(".com")
        ) {
            setEmailError(null);
        } else {
            if (email.trim().length === 0) {
                setEmail(email.trim())
                setEmailError("this field is required");
            } else {
                setEmailError("Invalid email");
            }
        }
        // semester validation
        if (semester === "Select semester") {
            setSemesterError(true);
        } else {
            setSemesterError(false);
        }
    };

    const submitHandler = (e) => {
        setIsLoading(true);
        validate();

        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            campus: college.id,
            course: course,
            semester: semester,
        };

        console.log(data);

        authApi
            .post("/users/profile/edit-profile/", data)
            .then((response) => {
                const { data, statusCode } = response.data;
                console.log(response.data);
                if (statusCode === 6000) {
                    setIsLoading(false);
                    dispatch(
                        authActions.updateUserData({
                            name: firstName,
                            lastName,
                            campusId: data.campus_id,
                        })
                    );
                    close();
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const fetchProfileData = () => {
        authApi
            .get("/users/profile/")
            .then((response) => {
                console.log(response.data);
                const { data, statusCode } = response.data;
                if (statusCode === 6000) {
                    setFirstName(data.first_name);
                    setEmail(data.email);
                    setOldEmail(data.email)
                    setCollege(data.campus);
                    setSemester(data.semester);
                    setLastName(data.last_name);
                    setPhoneNumber(data.phone);
                    setCourse(data.course);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const fetchCampuses = () => {
        apiConfig
            .get("/campuses/")
            .then((response) => {
                console.log(response.data);
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setColleges(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchProfileData()
        fetchCampuses()
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        rendererSettings: {},
    };

    const ShowCollegeModal = () => {
        const parentRef = document.getElementById("campusParent")

        const modalRef = useOutsideClick(() => setCollegeModal(false), parentRef)
        return (
            <SemesterModal className="active" ref={modalRef}>
                {colleges.map((college) => (
                    <li
                        key={college.id}
                        onClick={(e) => {
                            setCollege(college);
                            setCollegeModal(false);
                        }}
                    >
                        {`${college.name}, ${college.location}, ${college.district}`}
                    </li>
                ))}
            </SemesterModal>
        )
    }

    const ShowSemesterModal = () => {
        // onClick = {(e) => setShowModal(!showModal)}

        const parentRef = document.getElementById("semesterParent")
        const modalRef = useOutsideClick(() => setShowModal(false),parentRef)
        return (
            <SemesterModal
                ref={modalRef}
                className={showModal ? "active" : ""}
            >
                {semesters.map((semester) => (
                    <li
                        key={semester.id}
                        onClick={(e) => {
                            onSemesterChange();
                            setSemester(semester.title);
                            setShowModal(false);
                        }}
                    >
                        {semester.title}
                    </li>
                ))}
            </SemesterModal>
        )
    }

    return (
        <Wrapper onClick={(e) => close()}>
            <Container onClick={(e) => e.stopPropagation()}>
                <Top>
                    <h1>Edit Profile</h1>
                </Top>
                <Middle>
                    <InputsContainer>
                        <InputContainer isError={firstNameError ? true : false}>
                            <label htmlFor="first-name">First name</label>
                            <input
                                type="text"
                                id="first-name"
                                placeholder="First name"
                                value={firstName}
                                onChange={onFirstNameChange}
                                required
                            />
                            {firstNameError && (
                                <p className="error">{errorMessage}</p>
                            )}
                        </InputContainer>
                        <InputContainer>
                            <label htmlFor="last-name">Last name</label>
                            <input
                                type="text"
                                id="last-name"
                                placeholder="Last name"
                                value={lastName}
                                onChange={onLastNameChange}
                                required
                            />
                            {lastNameError && (
                                <p className="error">{errorMessage}</p>
                            )}
                        </InputContainer>
                    </InputsContainer>
                    <InputsContainer>
                        <InputContainer>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Email"
                                value={email}
                                onChange={onEmailChange}
                                required
                            />
                            {emailError && (
                                <p className="error">{emailError}</p>
                            )}
                        </InputContainer>
                        <InputContainer>
                            <label htmlFor="phone-number">Phone number</label>
                            <input
                                disabled
                                type="number"
                                id="phone-number"
                                placeholder="Phone number"
                                value={phoneNumber}
                                onChange={onPhoneChange}
                                required
                            />
                            {phoneNumberError && (
                                <p className="error">{errorMessage}</p>
                            )}
                        </InputContainer>
                    </InputsContainer>
                    <InputsContainer>
                        <InputContainer>
                            <label htmlFor="semester">College</label>
                            <SemesterContainer
                                id="campusParent"
                                onClick={(e) =>
                                    setCollegeModal(!showCollegeModal)
                                }
                                className={showCollegeModal ? "active" : ""}
                            >
                                <span>{`${college.name}, ${college.district}`}</span>
                                <img
                                    src={
                                        require("../../../assets/images/dashboard/Vector.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            </SemesterContainer>
                            {showCollegeModal && <ShowCollegeModal />}
                            {semesterError && (
                                <p className="error semester-error">
                                    {errorMessage}
                                </p>
                            )}
                        </InputContainer>
                        <InputContainer>
                            <label htmlFor="course">Course</label>
                            <input
                                type="text"
                                id="course"
                                placeholder="Course"
                                value={course}
                                onChange={onCourseChange}
                                required
                            />
                            {courseError && (
                                <p className="error">{errorMessage}</p>
                            )}
                        </InputContainer>
                    </InputsContainer>
                    <InputsContainer>
                        <InputContainer>
                            <label htmlFor="semester">Semester</label>
                            <SemesterContainer
                                id="semesterParent"
                                onClick={(e) => setShowModal(!showModal)}
                                className={showModal ? "active" : ""}
                            >
                                <span>{semester}</span>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Vector.svg"
                                    }
                                    alt=""
                                />
                            </SemesterContainer>
                            {showModal && <ShowSemesterModal />}
                            {semesterError && (
                                <p className="error semester-error">
                                    {errorMessage}
                                </p>
                            )}
                        </InputContainer>
                    </InputsContainer>
                </Middle>
                <Bottom>
                    <Actions>
                        <CancelButton onClick={(e) => close()}>
                            Cancel
                        </CancelButton>
                        <SaveButton onClick={submitHandler}>
                            {isLoading ? (
                                <Lottie
                                    options={defaultOptions}
                                    width={25}
                                    height={25}
                                />
                            ) : (
                                "Save changes"
                            )}
                        </SaveButton>
                    </Actions>
                </Bottom>
            </Container>
        </Wrapper>
    );
};

export default EditProfile;

const videoAnimation = keyframes`
    0% { 
        transform:scale(0,0); 
        opacity:0;
     }
    100% { 
        transform:scale(1,1); 
        opacity:1;
     }
`;
const Wrapper = styled.section`
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(133, 133, 133, 0.25);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    transition: background 0.6s ease-in-out;
`;
const Container = styled.div`
    cursor: auto;
    animation: ${videoAnimation} 0.6s;
    background: #ffffff;
    border-radius: 12px;
    max-width: 90%;
    width: 1060px;
    padding: 48px;
    max-height: 80vh;
    overflow-y: scroll;
    @media all and (max-width: 660px) {
        padding: 25px;
    }

    * {
        font-family: gordita_medium !important;
    }
`;

const Top = styled.div`
    margin-bottom: 24px;

    h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
    }
`;
const Middle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .left,
    .right {
        width: 50%;
    }
    @media all and (max-width: 660px) {
        flex-direction: column;
        .left,
        .right {
            width: 100%;
        }
    }
`;
const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Actions = styled.div`
    margin-top: 24px;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 1050px) {
        width: 100%;
    }
    @media (max-width: 460px) {
        flex-direction: column;
    }
`;
const CancelButton = styled.button`
    width: 50%;
    cursor: pointer;
    padding: 16px 0;
    border: 1px solid #adadad;
    border-radius: 6px;
    color: #adadad;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;

    @media (max-width: 460px) {
        padding: 8px 28px;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        margin-bottom: 12px;
    }
    @media (max-width: 390px) {
        padding: 6px 22px;
        font-size: 14px;
    }
    @media (max-width: 365px) {
        padding: 6px 12px;
    }
`;
const SaveButton = styled(CancelButton)`
    margin-left: 24px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    color: #fff;

    @media (max-width: 460px) {
        margin: 0;
    }
`;

const InputsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4%;
`

const InputContainer = styled.div`
    width: 48%;
    position: relative;
    label {
        display: block;
        color: #747474;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        font-family: gordita_regular;
    }
    input {
        width: 98%;
        margin: 16px 0;
        padding: 16px;
        font-size: 16px;
        font-weight: 500;
        font-family: gordita_medium;
        line-height: 22px;
        color: #0a0a0a;
        border: 1px solid #adadad;
        border-radius: 12px;

        ::placeholder {
            color: #adadad;
            font-weight: 500;
        }

        :disabled {
            color: #bdb5b59c;
            cursor: not-allowed;
            border: 1px solid #bdb5b59c;
        }
    }
    p.error {
        position: absolute;
        bottom: -6px;
        right: 30px;
        color: red;
        font-size: 14px;
        font-weight: 500;

        &.semester-error {
            bottom: -24px;
        }
    }
    @media all and (max-width: 660px) {
        width: 100%;
        label {
            font-size: 18px;
        }
        input {
            width: 95%;
            margin: 13px 0;
            padding: 12px;
            font-size: 17px;
        }
    }
`;
const SemesterContainer = styled.div`
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    color: #0a0a0a;
    border: 1px solid #adadad;
    margin: 16px 0;
    border-radius: 12px;
    cursor: pointer;
    span {
        font-family: gordita_regular;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #484747;
    }
    img {
        width: 20px;
        transition: all 0.4s ease 0s;
    }
    &.active {
        img {
            transform: rotate(-180deg);
        }
    }
    @media all and (max-width: 660px) {
        span {
            font-size: 17px;
        }
        img {
            width: 18px;
        }
    }
`;
const SemesterModal = styled.ul`
    position: absolute;
    top: 95px;
    left: 0;
    z-index: 111;
    height: 0;
    transition: height 4s ease 2s;

    &.active {
        height: max-content;
        max-height: 150px;
        /* height: 150px; */
    }

    width: 95%;
    border: 1px solid #adadad;
    border-radius: 12px;
    overflow-y: scroll;
    background: #fff;

    li {
        padding: 8px 16px;
        color: #484747;
        font-family: gordita_regular;
        font-size: 16px;
        :hover {
            background-color: rgb(240, 255, 244);
        }
        cursor: pointer;
    }
`;
