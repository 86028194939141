import React from "react";
import styled from "styled-components";

import kvups from "../../assets/images/Kvups.png";
import jamia_edavanna from "../../assets/images/Jamia-edavanna.png";
import na_model_ksd from "../../assets/images/Na-model-ksd.png";
import christ_college from "../../assets/images/Christ-college.png";
import lekshmi_vilasam from "../../assets/images/Lekshmi-vilasam .png";
import naher_college from "../../assets/images/Naher-college .png";
// import tribalpark_attappadi from "../../assets/images/Tribal-park-attappadi .png";
// import mes_kalladi from "../../assets/images/Mes-kalladi.png";
// import wmo_college from "../../assets/images/Wmo-college .png";

function Interview() {
    const techiesparks = [
        {
            id: 0,
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Kvups.png",
            boldtext: "KVUP School,",
            title: "Vamanapuram Assembly Constituency",
            link: "https://goo.gl/maps/u4CjdFHQFf6dFrmr7",
            // link: "https://g.page/talrop",
        },
        {
            id: 1,
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Jamia-edavanna.png",
            boldtext: "Jamia Nadwiyya,",
            title: "Eranad Assembly Constituency",
            link: "https://g.page/talrop-techies-park-edavanna",
        },
        {
            id: 2,
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Na-model-ksd.png",
            boldtext: "NA Model School,",
            title: "Kasaragod Assembly Constituency",
            link: "https://goo.gl/maps/7A7f14oHUGAcDrJPA",
        },
        {
            id: 3,
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Christ-college.png",
            boldtext: "Christ College of Engineering,",
            title: "Irinjalakuda Assembly Constituency",
            link: "https://goo.gl/maps/JMh4nbZuJjhsUS6TA",
        },
        {
            id: 4,
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Lekshmi-vilasam .png",
            boldtext: "Lekshmi Vilasom High School,",
            title: " Nedumangad Assembly Constituency",
            link: "https://maps.app.goo.gl/jBnvWJiHe6hZAeBU6",
            // link: "https://goo.gl/maps/A3BpVeTSPoz1wC3d7",
        },
        {
            id: 5,
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Naher-college .png",
            boldtext: "Naher Arts & Science College, ",
            title: "KMJ, Kannur Assembly Constituency",
            link: "https://maps.app.goo.gl/LdMyAF8qfKGu4CVn8",
        },
        // {
        //     id: 6,
        //     image: tribalpark_attappadi,
        //     boldtext: "Attappadi,",
        //     title: "Palakkad",
        //     link: "https://maps.app.goo.gl/iLj5Tv73hcxqFHR57",
        //     park: "tribal IT park",
        // },
        // {
        //     id: 7,
        //     image: mes_kalladi,
        //     boldtext: "MES Kalladi College,",
        //     title: "Mannarkkad Assembly Constituency",
        //     link: "https://maps.app.goo.gl/MrsqBHWBYDyJguaE8",
        // },
        // {
        //     id: 8,
        //     image: wmo_college,
        //     boldtext: "WMO Arts & Science College,",
        //     title: "Muttil, Kalpetta Assembly Constituency",
        //     link: "https://maps.app.goo.gl/MrsqBHWBYDyJguaE8",
        // },
    ];
    return (
        <MainContainer>
            <WrapperBox>
                <MainHeading>
                    <span>Interview</span> Locations
                </MainHeading>
                <DescriptionWrapper>
                    <Description>
                        The interviews are conducted in Talrop's Techies Parks
                        and associated colleges. Students can choose the
                        location which is convenient for them.
                    </Description>
                </DescriptionWrapper>
                <GridContainer>
                    {techiesparks.map((item) => (
                        <GridItem key={item.id}>
                            <TopBox>
                                <TechiesParkImageBox>
                                    <TechiesParkImage
                                        src={item.image}
                                        alt="techies-park"
                                    />
                                    {item.park && <span>{item.park}</span>}
                                </TechiesParkImageBox>
                                <Name>
                                    <span>{item.boldtext}</span>
                                    <br />
                                    {item.title}
                                </Name>
                            </TopBox>

                            <LocationBox
                                className="location"
                                target="_blank"
                                href={item.link}
                            >
                                <Direction>Directions</Direction>
                                <LocationIconBox>
                                    <LocationIcon
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/direction.svg"
                                        }
                                        alt="location-icon"
                                    />
                                </LocationIconBox>
                            </LocationBox>
                        </GridItem>
                    ))}
                </GridContainer>
            </WrapperBox>
        </MainContainer>
    );
}

export default Interview;

const MainContainer = styled.section`
    padding: 100px 0;
    background: linear-gradient(
        252.44deg,
        #fbf7ed 0%,
        rgba(234, 247, 250, 0.55) 100%
    );
    @media all and (max-width: 980px) {
        padding: 80px 0;
    }
    @media all and (max-width: 640px) {
        padding: 70px 0;
    }
    @media all and (max-width: 480px) {
        padding: 45px 0;
    }
`;
const WrapperBox = styled.section`
    width: 70%;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 74%;
    }
    @media all and (max-width: 1080px) {
        width: 77%;
    }
    @media all and (max-width: 980px) {
        width: 83%;
    }
    @media all and (max-width: 480px) {
        width: 88%;
    }
`;
const Title = styled.h3`
    text-align: center;
    font-family: "gordita_medium";
    color: #32bcad;
    margin-bottom: 15px;
    text-align: center;
    font-size: 28px;
    @media all and (max-width: 1480px) {
        font-size: 26px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 5px;
    }
    @media all and (max-width: 680px) {
        font-size: 24px;
    }
    @media all and (max-width: 550px) {
        font-size: 22px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
        margin-bottom: 0;
    }
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const MainHeading = styled.h1`
    text-align: center;
    color: #0a0a0a;
    font-size: 45px;
    font-family: "gordita_medium";
    margin-bottom: 25px;
    span {
        font-size: 45px;
        font-family: "gordita_medium";
        color: #32bcad;
        @media all and (max-width: 1480px) {
            font-size: 40px;
        }
        @media all and (max-width: 980px) {
            font-size: 36px;
        }
        @media all and (max-width: 680px) {
            font-size: 30px;
        }
        @media all and (max-width: 550px) {
            font-size: 28px;
        }
        @media all and (max-width: 480px) {
            font-size: 24px;
        }
        @media all and (max-width: 360px) {
            font-size: 23px;
        }
    }
    @media all and (max-width: 1480px) {
        font-size: 40px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 15px;
        font-size: 36px;
    }
    @media all and (max-width: 680px) {
        font-size: 30px;
    }
    @media all and (max-width: 550px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 23px;
    }
`;
const DescriptionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Description = styled.p`
    text-align: center;
    color: #7e7e7e;
    width: 80%;
    margin-bottom: 40px;
    font-size: 18px;
    @media all and (max-width: 1480px) {
        margin-bottom: 25px;
    }
    @media all and (max-width: 1280px) {
        font-size: 17px;
    }

    @media all and (max-width: 980px) {
        margin-bottom: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @media all and (max-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media all and (max-width: 768px) {
        grid-gap: 15px;
        grid-template-columns: repeat(1, 1fr);
    }
`;
const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f7f7f7;
    padding: 25px;
    border-radius: 5px;

    @media all and (max-width: 1480px) {
        padding: 20px;
    }
    @media all and (max-width: 980px) {
        padding: 10px;
    }
    @media all and (max-width: 768px) {
        padding: 15px;
    }
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const TopBox = styled.div`
    display: flex;
    flex-direction: column;
`;
const TechiesParkImageBox = styled.div`
    position: relative;
    margin-bottom: 20px;
    span {
        color: #32bcad;
        background: #f6f6f6;
        text-transform: capitalize;
        border-radius: 6px;
        padding: 6px 10px;
        font-size: 18px;
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-family: "gordita_medium";
        @media all and (max-width: 1080px) {
            font-size: 16px;
        }

        @media all and (max-width: 360px) {
            font-size: 14px;
        }
    }
`;
const TechiesParkImage = styled.img``;
const Name = styled.h1`
    margin-bottom: 35px;
    color: #545454;
    font-size: 15px;

    span {
        font-family: "gordita_medium";
        margin-right: 5px;
        font-size: 18px;
        text-transform: capitalize;
        @media all and (max-width: 1080px) {
            font-size: 17px;
        }
        @media all and (max-width: 980px) {
            font-size: 16px;
        }
    }
    @media all and (max-width: 1480px) {
        font-size: 18px;
    }
    @media all and (max-width: 1280px) {
        margin-bottom: 25px;
        font-size: 17px;
    }
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 15px;
        font-size: 15px;
        span {
            font-size: 17px;
        }
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
        & span {
            font-size: 17px;
        }
    }
`;
const LocationBox = styled.a`
    border: 1px solid #32bcad;
    padding: 12px 24px;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: max-content;
    background-color: #fff;

    @media all and (max-width: 980px) {
        padding: 10px 18px;
    }
    @media all and (max-width: 480px) {
        padding: 8px 14px;
    }
`;
const Direction = styled.a`
    margin-right: 15px;
    font-family: "gordita_medium";
    color: #32bcad;

    @media all and (max-width: 480px) {
        font-size: 17px;
    }
    @media all and (max-width: 380px) {
        font-size: 16px;
    }
`;
const LocationIconBox = styled.div`
    width: 22px;
`;
const LocationIcon = styled.img``;
