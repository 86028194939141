import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PrivateRoute from "../routes/PrivateRoute";
import AppRouter from "../routers/AppRouter";
import LandingPage from "../../screens/LandingPage";

// components
import AuthRouter from "./AuthRouter";
import Register from "../../screens/Register";
// import NotFound from "../../screens/dashboard/NotFound";

const MainRouter = () => {
    return (
        <Suspense>
            <Routes>
                {/* <Route path="*" element={<NotFound />} /> */}
                <Route path="/" element={<LandingPage />} />
                <Route path="register/" element={<Register />} />
                {/* <Route path="/auth/*" element={<AuthRouter />} />
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <AppRouter />
                        </PrivateRoute>
                    }
                /> */}
            </Routes>
        </Suspense>
    );
};

export default MainRouter;
const Cover = styled.div`
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;

// const HomePage = () => {
//     const navigate = useNavigate();
//     useEffect(() => {
//         navigate("/home");
//     });
// };
