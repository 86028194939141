// react imports
import React, { useEffect, useState } from "react";
// package imports
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Lottie from "react-lottie";
// local imports
import VideoCard from "../../includes/dashboard/VideoCard";
import page_loader from "../../../assets/lotties/page_loader.json";
import button_loader from "../../../assets/lotties/button_loader.json";
import { useDispatch } from "react-redux";
import { authActions } from "../../contexts/authSlice";
import useAuthApi from "../../hooks/useApi";

function OrientationVideos({ setMenu, isMenu }) {
    const [isWatched, setIsWatched] = useState(false);
    const [isOriented, setIsoriented] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [hasVideos, setVideos] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authApi = useAuthApi();

    const fetchDashboardContent = () => {
        authApi
            .get("/users/dashboard/")
            .then((response) => {
                const { statusCode, data } = response.data;
                console.log(response.data);

                if (statusCode === 6000) {
                    console.log(data.is_oriented);
                    setIsoriented(data.is_oriented);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                if (error.response.status === 401) {
                    dispatch(authActions.logout());
                }
            });
    };

    useEffect(() => {
        setIsLoading(true);
        fetchDashboardContent();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(false)
        }, 3000)
    }, [isError])

    const confirmWatched = (e) => {
        setIsButtonLoading(true);
        e.preventDefault();
        if (isWatched) {
            authApi
                .post("orientations/videos/watched/confirm/")
                .then((response) => {
                    console.log(response.data);
                    if (response.data.statusCode === 6000) {
                        setIsButtonLoading(false);
                        navigate("/dashboard/");
                    } else {
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsButtonLoading(false);
                });
        } else {
            setIsButtonLoading(false);
            setError(true);
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: page_loader,
        rendererSettings: {},
    };

    const buttonLoaderOptions = {
        loop: true,
        autoplay: true,
        animationData: button_loader,
        rendererSettings: {},
    };

    return (
        <>
            <Container>
                <Section id="main">
                    <Cover onClick={() => setMenu(!isMenu)}>
                        <Top>
                            <Heading>Orientation Videos</Heading>
                            <Description>Orientaion videos</Description>
                        </Top>
                        <div className="orientation">
                            {isLoading ? (
                                <LoaderWrapper>
                                    <Lottie
                                        options={defaultOptions}
                                        width={100}
                                        height={100}
                                    />
                                </LoaderWrapper>
                            ) : (
                                <>
                                    <ListItems className="anim-fade">
                                        <VideoCard setVideos={setVideos} />
                                    </ListItems>
                                    {!isOriented && hasVideos && (
                                        <>
                                            <BottomContainer>
                                                {isError && (
                                                    <Error>
                                                        Please Complete the
                                                        videos
                                                    </Error>
                                                )}
                                                <div>
                                                    <Label>
                                                        I have watched the
                                                        orientation video
                                                        <div
                                                            onClick={(e) =>
                                                                setIsWatched(
                                                                    !isWatched
                                                                )
                                                            }
                                                        >
                                                            {isWatched && (
                                                                <img
                                                                    src={
                                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/tick.svg"
                                                                    }
                                                                    alt="tick"
                                                                />
                                                            )}
                                                        </div>
                                                    </Label>
                                                    <Continue
                                                        className={
                                                            isButtonLoading
                                                                ? "loading"
                                                                : ""
                                                        }
                                                        onClick={confirmWatched}
                                                    >
                                                        {isButtonLoading ? (
                                                            <Lottie
                                                                options={
                                                                    buttonLoaderOptions
                                                                }
                                                                width={30}
                                                                height={30}
                                                            />
                                                        ) : (
                                                            "Continue"
                                                        )}
                                                    </Continue>
                                                </div>
                                            </BottomContainer>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </Cover>
                </Section>
            </Container>
        </>
    );
}

export default OrientationVideos;

const Container = styled.section`
    min-height: 100vh;
    height: 100vh;
`;
const Section = styled.section``;
const Cover = styled.div`
    padding: 36px 25px 30px;

    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        padding: 8px 25px 20px;
    }
    @media all and (max-width: 1080px) {
        padding: 31px 18px 20px;
    }
    @media all and (max-width: 980px) {
        padding: 31px 22px 20px;
    }
    @media all and (max-width: 768px) {
        padding-left: 10px;
    }
    @media all and (max-width: 480px) {
        padding-right: 10px;
    }
    div.orientation {
        min-height: 83vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 83vh;
        @media all and (max-width: 1280px) {
            max-height: 82vh;
            overflow: scroll;
            min-height: 82vh;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        @media all and (max-width: 1080px) {
            max-height: 70vh;
            min-height: 70vh;
        }
        @media all and (max-width: 980px) {
            max-height: 70vh;
            min-height: 70vh;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
`;
const Top = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const Heading = styled.h2`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 24px;
    margin-bottom: 9px;
    @media all and (max-width: 1280px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 21px;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 9px;
    }
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    color: #747474;
    font-size: 16px;
    font-family: gordita_regular;
    @media all and (max-width: 980px) {
        font-size: 15px;
    }
`;
const ListItems = styled.ul`
    margin-top: 20px;
    display: flex;
    justify-content: unset;
    flex-wrap: wrap;
    gap: 16px;
    animation: fadeIn 0.5s;
    @media all and (max-width: 1400px) {
    }
    @media all and (max-width: 1280px) {
        margin-top: 10px;
    }
    @media all and (max-width: 980px) {
        gap: 26px;
        justify-content: space-between;
    }
    @media all and (max-width: 768px) {
        gap: 23px;
    }
    @media all and (max-width: 640px) {
        gap: 16px;
    }
`;
const BottomContainer = styled.div`
    width: 100%;
    /* display: flex; */
    padding: 32px;
    
    &>span{
        display: block;
        text-align: end;
        margin-bottom: 12px;
    }
    &>div{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
    }
    @media all and (max-width: 980px) {
        margin-top: 15px;
    }
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;
const Label = styled.p`
    margin-right: 20px;
    color: #747474;
    display: flex;
    align-items: center;
    font-size: 14px;
    &>div {
        margin-left: 10px;
        width: 25px !important;
        height: 25px !important;
        cursor: pointer;
        border-radius: 5px;
        border: 2px solid #747474;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 20px;
            display: block;
        }
        @media all and (max-width: 360px) {
            width: 22px;
            height: 22px;
        }
    }
    @media all and (max-width: 1280px) {
        margin-right: 15px;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        display: flex;
        justify-content: space-between;
    }

    input {
        margin-left: 10px;
        font-size: 50px;
        @media all and (max-width: 480px) {
            margin-left: 10px;
        }
        @media all and (max-width: 360px) {
            margin: 0 0 0 5px;
        }
    }
`;
const Continue = styled(Link)`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    color: #fff;
    font-size: 20px;
    font-family: gordita_medium;
    padding: 12px 35px;
    cursor: pointer;

    &.loading {
        padding: 11.5px 72px;
    }

    @media all and (max-width: 1280px) {
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        padding: 10px 23px;
    }
    @media all and (max-width: 640px) {
        padding: 12px 25px;
    }
    @media all and (max-width: 480px) {
        padding: 11px 41px;
        margin-top: 20px;
        font-size: 18px;
    }
    @media all and (max-width: 360px) {
        font-size: 16px;
    }
`;
const Error = styled.span`
    color: #ff0000;
    /* position: absolute; */
    bottom: 5px;
    font-size: 12px;
    @media all and (max-width: 1280px) {
        bottom: 19px;
    }
    @media all and (max-width: 1080px) {
        bottom: 77px;
    }
    @media all and (max-width: 980px) {
        bottom: 87px;
    }
    @media all and (max-width: 640px) {
        bottom: 94px;
    }
    @media all and (max-width: 480px) {
        bottom: 83px;
    }
`;

const LoaderWrapper = styled.div`
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
