import React from "react";
import styled from "styled-components";

function Skills() {
    const skills = [
        { id: 0, skill: "HTML" },
        { id: 1, skill: "CSS" },
        { id: 2, skill: "JavaScript" },
        { id: 3, skill: "jQuery" },
        { id: 4, skill: "Sass" },
        { id: 4, skill: "Bootstrap" },
        { id: 6, skill: "ReactJS" },
        { id: 7, skill: "PWA" },
        { id: 8, skill: "Python" },
        { id: 9, skill: "Django" },
        { id: 10, skill: "PostgreSQL" },
        { id: 11, skill: "Git" },
    ];

    return (
        <MainContainer>
            <WrapperBox>
                <Heading>What does the candidate learn?</Heading>
                <Description>
                Candidates develop skill sets that caters specifically to industry needs, with a focus on the IT industry.
                </Description>
                <ListBox>
                    {skills.map((obj) => (
                        <ListItem key={obj.id}>{obj.skill}</ListItem>
                    ))}
                </ListBox>
            </WrapperBox>
        </MainContainer>
    );
}

export default Skills;

const MainContainer = styled.section`
    background: linear-gradient(96.55deg, #19244e 0.05%, #0a1231 99.48%);
    padding: 100px 0;
    @media all and (max-width: 980px) {
        padding: 80px 0;
    }
    @media all and (max-width: 640px) {
        padding: 70px 0;
    }
    @media all and (max-width: 480px) {
        padding: 45px 0;
    }
`;
const WrapperBox = styled.section`
    width: 80%;
    margin: 0 auto;
`;
const Heading = styled.h1`
    text-align: center;
    color: #32bcad;
    font-size: 45px;
    font-family: "gordita_medium";
    margin-bottom: 15px;
    @media all and (max-width: 1480px) {
        font-size: 40px;
    }
    @media all and (max-width: 980px) {
        font-size: 36px;
    }
    @media all and (max-width: 680px) {
        font-size: 30px;
    }
    @media all and (max-width: 550px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 23px;
    }
`;
const Description = styled.p`
    text-align: center;
    color: #eeeeee;
    width: 42%;
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.9em;
    font-family: "gordita_regular";
    margin-bottom: 40px;
    @media all and (max-width: 1800px) {
        width: 50%;
    }
    @media all and (max-width: 1500px) {
        width: 60%;
    }
    @media all and (max-width: 1400px) {
        width: 65%;
    }
    @media all and (max-width: 1300px) {
        width: 70%;
    }
    @media all and (max-width: 1200px) {
        width: 75%;
    }
    @media all and (max-width: 1100px) {
        width: 86%;
    }
    @media all and (max-width: 680px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const ListBox = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 78%;
    margin: 0 auto;
    gap: 15px;
    @media all and (max-width: 1280px) {
        width: 85%;
    }
    @media all and (max-width: 1080px) {
        width: 92%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        gap: 10px;
    }
    @media all and (max-width: 768px) {
        gap: 8px;
    }
    @media all and (max-width: 640px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;

const ListItem = styled.span`
    color: #fff;
    font-size: 24px;
    padding: 16px 28px;
    border-radius: 15px;
    border: 1px solid #fff;

    &:last-child {
    }
    @media all and (max-width: 1720px) {
        padding: 14px 28px;
        font-size: 22px;
    }
    @media all and (max-width: 1650px) {
        padding: 12px 24px;
    }
    @media all and (max-width: 1600px) {
        font-size: 20px;
        padding: 14px 25px;
    }
    @media all and (max-width: 1485px) {
        font-size: 19px;
        padding: 10px 20px;
    }
    @media all and (max-width: 1400px) {
        font-size: 18px;
    }
    @media all and (max-width: 1310px) {
        font-size: 18px;
        padding: 12px;
    }
    @media all and (max-width: 980px) {
        padding: 12px 9px;
        font-size: 16px;
    }

    @media all and (max-width: 780px) {
        font-size: 16px;
    }
    @media all and (max-width: 650px) {
        width: 45%;
        text-align: center;
        font-size: 15px;
    }
    @media all and (max-width: 570px) {
        font-size: 16px;
    }
    @media all and (max-width: 500px) {
        padding: 8px 15px;
        width: 45%;
        text-align: center;
    }
`;
