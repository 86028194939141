import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import line from "../../assets/images/dashed-lines.png";

import ContactUs from "../includes/modals/landing_page/ContactUs";
// import $ from "jquery"

function Process() {
    const [modalTop, setModalTop] = useState(0);
    const [showContact, setContact] = useState(false);
    const outsidehandler = () => {
        setContact(!showContact);
    };

    useEffect(() => {
        const onScroll = () => {
            setContact(false);
        };
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    // $(window).scroll(function () {
    //     var scrollTop = $(window).scrollTop(),
    //         elementOffset = $("#contact").offset().top,
    //         distance = elementOffset - scrollTop;

    //     if (distance < $(window).height()) {
    //         $("#contact").css({
    //             position: "absolute",
    //             top: "334px",
    //         });
    //     } else {
    //         $("#contact").css({
    //             position: "fixed",
    //             top: "auto",
    //         });
    //     }
    // });
    const modalRef = useRef(null);

    return (
        <MainContainer ref={modalRef}>
            <WrapperBox>
                <Heading>
                    The <span>Process</span>
                </Heading>
                <Contents>Process to get selected for the programme.</Contents>
                <ListBox>
                    <ListItem>
                        <BackgroundBox className="orange">
                            <ProcessStage className="first">01</ProcessStage>
                        </BackgroundBox>
                        <Description>
                            Get enrolled through the Placement Cell
                        </Description>
                        <Button
                            onClick={(e) => {
                                setContact(!showContact);
                                if (e.clientY + 389 + 32 > window.innerHeight) {
                                    setModalTop(-29);
                                } else {
                                    setModalTop(329);
                                }
                            }}
                            id="contact-us-button"
                        >
                            Contact Us
                        </Button>
                        {showContact && (
                            <ContactUs
                                modalTop={modalTop}
                                handler={outsidehandler}
                            />
                        )}
                        <BorderBox>
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/green-sun.svg"
                                }
                                alt=""
                            />
                        </BorderBox>
                    </ListItem>
                    <ListItem>
                        <BackgroundBox className="green">
                            <ProcessStage className="second">02</ProcessStage>
                        </BackgroundBox>
                        <Description>Attend one-to-one Interview</Description>
                        <BorderBox>
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/green-sun.svg"
                                }
                                alt=""
                            />
                        </BorderBox>
                    </ListItem>
                    <ListItem>
                        <BackgroundBox className="blue">
                            <ProcessStage className="third">03</ProcessStage>
                        </BackgroundBox>
                        <Description>
                            Get started through selection process
                        </Description>
                        <BorderBox>
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/green-sun.svg"
                                }
                                alt=""
                            />
                        </BorderBox>
                    </ListItem>
                </ListBox>
                <BottomBox>
                    <BottomHeading>
                        Only 25 students will be selected from a college after
                        the interview.
                    </BottomHeading>
                    <Paragraph>
                        The selected students will be divided into two separate
                        lists :
                    </Paragraph>
                    <MiddleBox>
                        <LeftBox>
                            <Number>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/dashed-one.svg"
                                    }
                                    alt=""
                                />
                            </Number>
                            <SubTitle>Priority list</SubTitle>
                        </LeftBox>
                        <RightBox>
                            <Number>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/dashed-two.svg"
                                    }
                                    alt=""
                                />
                            </Number>
                            <SubTitle>Waiting list</SubTitle>
                        </RightBox>
                    </MiddleBox>
                    <MiddleBorder></MiddleBorder>
                    <DetailedDescription>
                        The initial 25 chosen candidates will be placed on the
                        priority list. If any individuals from this list fail to
                        join, candidates from the waiting list will be offered
                        the opportunity.
                    </DetailedDescription>
                    <Circle>
                        <CircleImage
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/round.svg"
                            }
                            alt="round"
                        />
                    </Circle>
                    <Rectangle>
                        <RectangleImage
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/rectangular.svg"
                            }
                            alt="rectangel"
                        />
                    </Rectangle>
                </BottomBox>
            </WrapperBox>
        </MainContainer>
    );
}

export default Process;

const Contents = styled.p`
    text-align: center;
    color: #7e7e7e;
    width: 80%;
    font-size: 18px;
    margin: 0 auto 40px;
    @media all and (max-width: 1480px) {
        margin-bottom: 25px;
    }
    @media all and (max-width: 1280px) {
        font-size: 17px;
    }

    @media all and (max-width: 980px) {
        margin-bottom: 20px;
    }
    @media all and (max-width: 640px) {
        width: 96%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;






const MainContainer = styled.section`
    padding: 100px 0;
    background-color: #fff;
    @media all and (max-width: 980px) {
        padding: 80px 0;
    }
    @media all and (max-width: 640px) {
        padding: 70px 0;
    }
    @media all and (max-width: 480px) {
        padding: 45px 0;
    }
`;
const WrapperBox = styled.section`
    width: 80%;
    margin: 0 auto;
    @media all and (max-width: 480px) {
        width: 85%;
    }
`;

const Heading = styled.h1`
    text-align: center;
    color: #0a0a0a;
    font-size: 45px;
    font-family: "gordita_medium";
    margin-bottom: 25px;
    span {
        font-size: 45px;
        font-family: "gordita_medium";
        color: #32bcad;
        @media all and (max-width: 1480px) {
            font-size: 40px;
        }
        @media all and (max-width: 980px) {
            font-size: 36px;
        }
        @media all and (max-width: 680px) {
            font-size: 32px;
        }
        @media all and (max-width: 550px) {
            font-size: 29px;
        }
        @media all and (max-width: 480px) {
            font-size: 26px;
        }
        @media all and (max-width: 360px) {
            font-size: 24px;
        }
    }

    @media all and (max-width: 1480px) {
        font-size: 40px;
    }
    @media all and (max-width: 980px) {
        font-size: 36px;
    }
    @media all and (max-width: 680px) {
        font-size: 30px;
    }
    @media all and (max-width: 550px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 24px;
    }
`;
const ListBox = styled.ul`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-image: url(${line});
    background-position: right;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position-y: 35%;
    background-position-x: 47%;
    position: relative;
    z-index: 10;

    @media all and (max-width: 1120px) {
        flex-wrap: wrap;
        justify-content: space-around;
        background-image: none;
    }
`;
const ListItem = styled.li`
    width: 26%;
    height: 380px;
    padding: 0 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #32bcad;
    position: relative;
    background: linear-gradient(
        180deg,
        rgba(254, 252, 252, 0) 0%,
        #fefcfc 100%
    );

    @media all and (max-width: 1700px) {
        width: 27%;
    }
    @media all and (max-width: 1500px) {
        width: 28%;
    }
    @media all and (max-width: 1230px) {
        width: 30%;
    }
    @media all and (max-width: 1150px) {
        width: 31%;
    }
    @media all and (max-width: 1120px) {
        width: 46%;
        &:last-child {
            margin-top: 40px;
        }
    }
    @media all and (max-width: 768px) {
        width: 100%;
        margin-top: 50px;
        &:first-child {
            margin-top: 0;
        }
    }
`;
const BackgroundBox = styled.div`
    width: 120px;
    height: 120px;
    opacity: 0.8;
    border-radius: 50%;
    position: relative;
    margin-bottom: 25px;
    &.orange {
        background: #ffecc5;
    }
    &.green {
        background: #e9f7f2;
        opacity: 1;
    }
    &.blue {
        background: #e8def8;
    }
    @media all and (max-width: 480px) {
        width: 90px;
        height: 90px;
    }
`;
const ProcessStage = styled.h1`
    font-size: 68px;
    position: absolute;
    font-family: "gordita_bold";
    -webkit-text-stroke: 4px #fff;
    bottom: -30px;
    right: 0;
    z-index: 2;
    &.first {
        color: #f2b332;
    }
    &.second {
        color: #3dc091;
    }
    &.third {
        color: #9257ef;
    }
    @media all and (max-width: 1780px) {
        bottom: -30px;
        right: -10px;
    }
    @media all and (max-width: 1480px) {
        font-size: 60px;
        bottom: -26px;
        right: -5px;
    }
    @media all and (max-width: 480px) {
        font-size: 50px;
    }
`;
const Description = styled.p`
    color: #0a0a0a;
    font-size: 18px;
    text-align: center;
    font-family: "gordita_medium";
    line-height: 1.6em;
    width: 90%;

    @media all and (max-width: 1120px) {
        width: 70%;
        font-size: 16px;
    }
    @media all and (max-width: 945px) {
        width: 85%;
    }
    @media all and (max-width: 855px) {
        width: 96%;
    }
    @media all and (max-width: 640px) {
        width: 86%;
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        width: 75%;
        font-size: 17px;
    }
    @media all and (max-width: 410px) {
        width: 100%;
    }

    @media all and (max-width: 360px) {
        font-size: 15px;
    }
`;
const BorderBox = styled.div`
    border-radius: 50%;
    /* border: 2px dotted #32bcad; */
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -25px;
    z-index: -1;
`;
const BackgroundCircle = styled.span`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #32bcad;
`;

const BottomBox = styled.div`
    margin-top: 60px;
    background: linear-gradient(96.55deg, #19244e 0.05%, #0a1231 99.48%);
    border-radius: 16px;
    padding: 60px 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    @media all and (max-width: 980px) {
        padding: 60px;
    }
    @media all and (max-width: 680px) {
        padding: 40px;
    }
    @media all and (max-width: 560px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        padding: 15px;
    }
`;
const BottomHeading = styled.h1`
    color: #32bcad;
    font-size: 32px;
    margin-bottom: 25px;
    text-align: center;
    font-family: "gordita_medium";
    @media all and (max-width: 1280px) {
        font-size: 28px;
    }
    @media all and (max-width: 1080px) {
        text-align: center;
    }
    @media all and (max-width: 768px) {
        font-size: 26px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 680px) {
        font-size: 24px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 21px;
    }
`;
const Paragraph = styled.p`
    color: #eeeeee;
    font-size: 20px;
    margin-bottom: 20px;
    @media all and (max-width: 1280px) {
        font-size: 18px;
    }
    @media all and (max-width: 680px) {
        text-align: center;
    }

    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const MiddleBox = styled.div`
    width: 50%;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 1280px) {
        width: 59%;
    }
    @media all and (max-width: 1080px) {
        width: 69%;
    }
    @media all and (max-width: 980px) {
        width: 72%;
    }
    @media all and (max-width: 850px) {
        width: 83%;
    }
    @media all and (max-width: 710px) {
        width: 80%;
    }

    @media all and (max-width: 640px) {
        width: 86%;
    }
    @media all and (max-width: 550px) {
        width: 98%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
`;
const MiddleBorder = styled.span`
    width: 300px;
    height: 1px;
    background-color: rgba(44, 167, 154, 0.3);
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const LeftBox = styled.div`
    display: flex;
    align-items: center;
`;
const Number = styled.span`
    /* color: #32bcad; */
    /* border: 4px dashed #32bcad; */
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;

    span {
        color: inherit;
        font: inherit;
    }

    @media all and (max-width: 560px) {
        width: 40px;
        height: 40px;
    }
    @media all and (max-width: 480px) {
        width: 35px;
        height: 35px;
    }
    @media all and (max-width: 420px) {
        width: 32px;
        height: 32px;
        font-size: 15px;
    }
    @media all and (max-width: 380px) {
        width: 30px;
        height: 30px;
        margin-right: 7px;
    }
    @media all and (max-width: 360px) {
        width: 27px;
        height: 27px;
    }
`;
const SubTitle = styled.h2`
    color: #fff;
    font-size: 32px;
    font-family: "gordita_medium";
    @media all and (max-width: 1450px) {
        font-size: 28px;
    }
    @media all and (max-width: 1280px) {
        font-size: 26px;
    }
    @media all and (max-width: 980px) {
        font-size: 24px;
    }
    @media all and (max-width: 768px) {
        font-size: 22px;
    }
    @media all and (max-width: 680px) {
        font-size: 20px;
    }
    @media all and (max-width: 560px) {
        font-size: 18px;
    }
    @media all and (max-width: 420px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const RightBox = styled.div`
    display: flex;
    align-items: center;
`;
const DetailedDescription = styled.p`
    color: #d4d4d4;
    font-size: 18px;
    width: 75%;
    text-align: center;
    margin-top: 20px;
    @media all and (max-width: 1450px) {
        width: 95%;
    }
    @media all and (max-width: 1280px) {
        margin-top: 30px;
        width: 73%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        margin-top: 15px;
        width: 100%;
        font-size: 14px;
    }
`;

const Circle = styled.div`
    position: absolute;
    top: 42%;
    left: 10%;
    opacity: 0.4;
    @media all and (max-width: 1280px) {
        top: 60%;
        left: 12%;
        width: 80px;
        height: 80px;
    }
    @media all and (max-width: 1080px) {
        top: 68%;
        left: 12%;
        width: 72px;
        height: 72px;
    }
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const CircleImage = styled.img``;
const Rectangle = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.4;
    &.last {
        left: -6%;
        bottom: -16%;
    }
    @media all and (max-width: 1450px) {
        width: 100px;
        height: 100px;
    }
    @media all and (max-width: 1080px) {
        width: 80px;
        height: 80px;
    }
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const RectangleImage = styled.img``;

const Button = styled.button`
    cursor: pointer;
    padding: 10px 20px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    color: #fff;
    font-family: gordita_regular;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    margin-top: 16px;
`;
