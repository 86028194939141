import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import promotion from "../../assets/images/probanner.svg";
// import ReactPlayer from "react-player/lazy";
import Header from "../includes/Header";
import "../../assets/css/style.css";
import EnterDetails from "../includes/modals/EnterDetails";
import OtpModal from "../includes/modals/OtpModal";
import SuccessModal from "../includes/modals/SuccessModal";
import {Helmet} from "react-helmet";
import frontImage from "../../assets/images/frontimage.jpg"

function Register() {
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [studentDivision, setStudentDivision] = useState("");
    const [campusPk, setCampusPk] = useState("");
    const [CampusName, setCampusName] = useState("");

    const [isClass, setClass] = useState("");
    const [isStream, setIsStream] = useState("");
    const [stream, setStream] = useState("");
    const [isDetailsModal, setDetailsModal] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [isregistered, setIsRegistered] = useState("");
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const clearInputField = () => {
        setName("");
        setPhone("");
        setStudentDivision("");
        setClass("");
        setIsStream("");
        setCampusName("");
    };
    const handleResize = () => {
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
        if (isDetailsModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isDetailsModal]);

    return (
        <>
            <Header />
            <Helmet title="Jobmission-jobified"/>
            <Container height={windowHeight} id="home">
                {isDetailsModal && (
                    <EnterDetails
                        isDetailsModal={isDetailsModal}
                        setDetailsModal={setDetailsModal}
                        phone={phone}
                        setPhone={setPhone}
                        name={name}
                        CampusName={CampusName}
                        setCampusName={setCampusName}
                        setName={setName}
                        studentDivision={studentDivision}
                        setStudentDivision={setStudentDivision}
                        campusPk={campusPk}
                        setCampusPk={setCampusPk}
                        isClass={isClass}
                        setClass={setClass}
                        isStream={isStream}
                        setStream={setStream}
                        stream={stream}
                        setIsStream={setIsStream}
                        setOtpModal={setOtpModal}
                        isregistered={isregistered}
                        setIsRegistered={setIsRegistered}
                    />
                )}
                {isOtpModal && (
                    <OtpModal
                        phone={phone}
                        name={name}
                        studentDivision={studentDivision}
                        campusPk={campusPk}
                        CampusName={CampusName}
                        isClass={isClass}
                        isStream={isStream}
                        isOtpModal={isOtpModal}
                        setOtpModal={setOtpModal}
                        setSuccessModal={setSuccessModal}
                        clearInputField={clearInputField}
                    />
                )}
                {successModal && (
                    <SuccessModal
                        successModal={successModal}
                        setSuccessModal={setSuccessModal}
                    />
                )}
                <Wrapper className="wrapper">
                    <Landing>
                        <LandingLeft>
                            <Head>
                                Bridging the Industry-Academic Gap Through Jobs Mission!
                            </Head>
                            <Subhead>
                                Talrop's 10,000 Jobs Mission is a project that aims to 
                                bridge the industry-academic gap in campuses and make the students 
                                industry ready.
                            </Subhead>

                            <Initiative>
                                {/* <img
                                src="https://creatorclub.s3.ap-south-1.amazonaws.com/production/assets/promotion.svg"
                                alt="Branding"
                            /> */}
                                <img src={promotion} />
                            </Initiative>
                            <RegisterButton
                                onClick={() => {
                                    setDetailsModal(true);
                                }}
                            >
                                Register
                            </RegisterButton>
                        </LandingLeft>
                        <LandingRight>
                            <Banner>
                                <img
                                    src={frontImage}
                                    alt="School"
                                />
                                {/* <Video>
                                    <ReactPlayer
                                        url="https://youtu.be/T5hOw5M5TeE"
                                        playing={true}
                                        loop={true}
                                        controls={true}
                                        config={{
                                            youtube: {
                                                playerVars: { showinfo: 1 },
                                            },
                                        }}
                                        width="100%"
                                        height="100%"
                                    />
                                </Video> */}
                            </Banner>
                        </LandingRight>
                    </Landing>
                </Wrapper>
            </Container>
        </>
    );
}

export default Register;

const Container = styled.section`
    padding: 140px 0 7%;
    min-height: ${(props) => props.height};
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        padding-top: 50px;
    }
    @media all and (max-width: 1080px) {
        padding-top: 30px;
    }
    @media all and (max-width: 980px) {
        padding-top: 15px;
    }
    @media all and (max-width: 640px) {
        padding-bottom: 30px;
    }
`;
const Wrapper = styled.section``;
const Landing = styled.div`
    width: 100%;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    @media all and (max-width: 480px) {
        padding-top: 70px;
    }
`;
const LandingLeft = styled.div`
    width: 50%;
    @media all and (max-width: 1280px) {
        padding-top: 45px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        padding-top: 0px;
    }
`;

const Head = styled.h3`
    font-weight: 700;
    font-size: 38px;
    text-align: center;
    color: #18484c;
    margin-bottom: 30px;
    text-align: left;
    position: relative;
    width: 100%;

    & b {
        color: #501c58;
        font-weight: 700;
    }

    &::after {
        content: "";
        position: absolute;
        width: 500px;
        height: 500px;
        background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/ykipp/25-05-2022/background.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        top: -165px;
        z-index: -1;
        left: -126px;
    }

    @media all and (max-width: 1280px) {
        font-size: 38px;
    }
    @media all and (max-width: 1080px) {
        font-size: 36px;
    }
    @media all and (max-width: 980px) {
        text-align: center;
        max-width: 100%;
        font-size: 28px;
    }
    @media all and (max-width: 640px) {
        font-size: 25px;
        &::after {
            width: 250px;
            height: 250px;
            background-size: 100%;
            top: -80px;
        }
    }
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
        font-size: 22px;
        text-align: unset;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;

const Banner = styled.div`
    height: 100%;
    width: 100%;
`;
const Video = styled.div`
    position: absolute;

    width: 600px;
    height: 400px;
`;
const Subhead = styled.p`
    color: #7d7d7d;
    font-size: 16px;
    margin-bottom: 20px;
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
        margin-bottom: 20px;
        text-align: unset;
    }
`;
const LineContainer = styled.div`
    width: 55%;
    @media all and (max-width: 980px) {
        margin: 0 auto;
    }
    @media all and (max-width: 480px) {
        margin: unset;
    }
`;
const Initiative = styled.div`
    color: #7d7d7d;
    font-size: 16px;
    margin-top: 25px;
    width: 80%;
    -webkit-box-align: center;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 1080px) {
        margin-top: none;
    }
    @media all and (max-width: 980px) {
        text-align: center;
        margin: auto 70px;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
        width: 100%;
        margin: 0 auto;
    }
    @media all and (max-width: 480px) {
        text-align: unset;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const Brand = styled.a`
    color: #4a4a4a;
    font-weight: 900;
    font-size: 16px;
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const RegisterButton = styled(Link)`
    width: 236px;
    font-size: 16px;
    padding: 15px 0;
    color: #fff;
    background: #96ca4d;
    text-align: center;
    font-family: "gordita_medium";
    border-radius: 8px;
    cursor: pointer;
    margin-top: 30px;
    display: block;
    @media all and (max-width: 1280px) {
        font-size: 15px;
    }
    @media all and (max-width: 1080px) {
        width: 270px;
    }
    @media all and (max-width: 980px) {
        margin: 0 auto;
        margin-top: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
        width: 200px;
        margin: 30px auto;
    }
    @media all and (max-width: 360px) {
        width: 220px;
    }
`;
const LandingRight = styled.div`
    width: 45%;
    position: relative;

    & .mobile {
        display: none;
    }
    @media all and (max-width: 1080px) {
        margin-top: 70px;
    }
    @media all and (max-width: 980px) {
        width: 65%;
        margin: 0 auto 60px;

        & .mobile {
            display: block;
        }
        & .web {
            display: none;
        }
    }
    @media all and (max-width: 768px) {
        margin-bottom: 70px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 40px;
    }
`;
