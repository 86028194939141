import React from "react";
import styled from "styled-components";
import Footer from "../includes/Footer";
import Header from "../includes/Header";
import Interview from "./Interview";
import Placement from "./Placement";
import Process from "./Process";
import Skills from "./Skills";
import Spotlight from "./Spotlight";

function LandingPage() {
    return (
        <>
            <MainContainer id="home">
                <Header />
                <Spotlight />
                <Placement />
                <Skills />
                <Process />

                {/* <Interview /> */}
                <Footer />
            </MainContainer>
        </>
    );
}

export default LandingPage;

const MainContainer = styled.section`
    background: linear-gradient(
        252.44deg,
        rgba(251, 247, 237, 0.1) 0%,
        #eaf7fa 100%
    );
`;
