import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import useAuthApi from "../../hooks/useApi";

import VideoModal from "../modals/VideoModal";
import playIcon from "../../../assets/images/dashboard/play.svg";
import $ from "jquery";

function VideoCard({ setVideos }) {
    // for open modal
    const [isModal, setModal] = useState(false);

    const [modalId, setModalId] = useState(true);
    const [items, setItems] = useState([]);

    const authApi = useAuthApi();

    useEffect(() => {
        authApi
            .get("/orientations/videos/")
            .then((response) => {
                let data = response.data;

                if (data.statusCode === 6000) {
                    setItems(data.data);
                    if (data.data.length >= 1) {
                        setVideos(true);
                    } else {
                        setVideos(false);
                    }
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    function handleScroll() {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }
    useEffect(() => {
        handleScroll();
    }, [isModal]);
    return (
        <>
            {items.map((item,index) => {
                // let str = `${item.order_id}`;
                let str = `${index + 1}`;
                return (
                    <Container key={item.id}>
                        <ImageContainer
                            onClick={(e) => {
                                setModal(true);
                                setModalId(item.id);
                            }}
                        >
                            <img src={item.thumbnail} alt="thumbnail" />
                            <PlayIcon>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/play.svg"
                                    }
                                    alt="icon"
                                />
                            </PlayIcon>
                        </ImageContainer>

                        <BottomSection>
                            <Count>#{str.length === 1 ? `0${str}` : str}</Count>
                            <Title>{item.title.toUpperCase()}</Title>
                            <SubTitle>{item.description}</SubTitle>
                        </BottomSection>
                    </Container>
                );
            })}
            {isModal && (
                <VideoModal
                    isModal={isModal}
                    setModal={setModal}
                    _item={modalId}
                    items={items}
                />
            )}
        </>
    );
}

export default VideoCard;
const Container = styled.div`
    cursor: pointer;
    width: 23%;
    background: #f0f9f9;
    padding: 15px;
    border-radius: 8px;
    &:nth-child(5) {
        margin-bottom: 0;
    }
    &:nth-child(6) {
        margin-bottom: 0;
    }
    &:nth-child(7) {
        margin-bottom: 0;
    }
    &:nth-child(8) {
        margin-bottom: 0;
    }
    @media all and (max-width: 1400px) {
        width: 23%;
    }
    @media all and (max-width: 1280px) {
        width: 23%;
        padding: 10px;
    }

    @media all and (max-width: 1080px) {
        width: calc(33% - 10px);
        padding: 16px;
    }
    @media all and (max-width: 980px) {
        padding: 22px;
        width: 48%;
    }
    @media all and (max-width: 640px) {
        padding: 11px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const ImageContainer = styled.div`
    width: 100%;
    display: block;
    position: relative;

    img {
        border-radius: 8px;
    }
`;
const PlayIcon = styled.div`
    width: 45px;
    position: absolute;
    top: 34%;
    left: 41%;

    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 1400px) {
        top: 34%;
        left: 41%;
    }
    @media all and (max-width: 1280px) {
        width: 36px;
        position: absolute;
        left: 40%;
        top: 34%;
        z-index: 1;
    }
    @media all and (max-width: 1080px) {
        left: 45%;
    }
    @media all and (max-width: 980px) {
        width: 50px;
        left: 43%;
    }
    @media all and (max-width: 640px) {
        left: 40%;
        width: 45px;
    }
    @media all and (max-width: 480px) {
        width: 50px;
        top: 38%;
        left: 42%;
    }
    @media all and (max-width: 360px) {
    }
`;
const BottomSection = styled.div`
    margin-top: 10px;
`;
const Count = styled.span`
    color: #0fa76f;
    font-size: 14px;
    font-family: gordita_medium;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const Title = styled.h4`
    font-size: 14px;
    font-family: gordita_medium;
    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const SubTitle = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #747474;
    font-family: gordita_regular;
    font-size: 14px;
    margin-top: 5px;

    @media all and (max-width: 1280px) {
        font-size: 12px;
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
