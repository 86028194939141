import moment from "moment/moment";
import React from "react";
import styled, { keyframes } from "styled-components";

function SuccessModal({ isSuccess, setSuccess, interviewTime, offlineTime, timeList }) {
    console.log(offlineTime);
    return (
        <>
            <Container>
                <Overlay onClick={() => setSuccess(!isSuccess)}></Overlay>
                <Section>
                    <ImgContainer>
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/success.svg"
                            }
                            alt="success"
                        />
                    </ImgContainer>
                    <Title>Successfully Scheduled !</Title>
                    <Description>
                        Your walk-in interview has been scheduled at{" "}
                        <span>{isSuccess.location}</span> on{" "}
                        <span>
                            {moment(isSuccess.date).format("DD-MM-YYYY")}{" "}
                        </span>{" "}
                        in-between{" "}
                        {/* <span>{isSuccess.time.split(":",2).join(":")}</span>. */}
                        <span>{timeList.map((time) => time.time === offlineTime && time.title)}</span>
                    </Description>
                    <Button onClick={() => setSuccess(false)}>
                        continue
                    </Button>
                </Section>
            </Container>
        </>
    );
}

export default SuccessModal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    z-index: 100000;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
    animation-name: ${videoAnimation};
    animation-duration: 0.3s;
`;
const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(10px);
    cursor: pointer;
`;
const Section = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    background-color: #fff;
    width: 50%;
    padding: 40px;
    align-items: center;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1280px) {
        width: 65%;
    }
    @media all and (max-width: 1080px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 80%;
    }
    @media all and (max-width: 640px) {
        width: 88%;
        padding: 36px;
    }
    @media all and (max-width: 480px) {
        width: 92%;
        padding: 25px;
    }
`;
const ImgContainer = styled.div`
    width: 50%;
    margin: 0 auto;
    @media all and (max-width: 480px) {
        width: 70%;
    }
`;
const Title = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 23px;
    margin-bottom: 10px;
    @media all and (max-width: 640px) {
        font-size: 18px;
    }
`;
const Description = styled.p`
    color: #747474;
    font-size: 17px;
    width: 65%;
    margin: 0 auto;
    font-family: gordita_regular;
    margin-bottom: 20px;
    text-align: center;
    @media all and (max-width: 1280px) {
        width: 75%;
    }
    @media all and (max-width: 1080px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 98%;
    }
    @media all and (max-width: 640px) {
        font-size: 13px;
        width: 100%;
    }
    span {
        color: #009262;
        font-size: 14px;
        font-family: gordita_medium;
    }
`;
const Button = styled.button`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    color: #fff;
    font-size: 20px;
    text-transform: capitalize;
    width: 159px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media all and (max-width: 768px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 17px;
    }
`;
