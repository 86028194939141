import React from "react";
import styled from "styled-components";

function Placement() {
    return (
        <MainContainer>
            <WrapperBox className="wrapper">
                <MainHeading>
                    Our <span>Placement </span> Program
                </MainHeading>
                <Description>
                    Jobified has come up with a solution to ment the industry-academic gap.
                </Description>
                <CardsContainer>
                    <CardBox>
                        <Heading className="first">
                            <span className="small-title">
                                In
                                <span className="bg one"></span>
                            </span>
                            1st Year
                        </Heading>
                        <Paragraph>
                            Develop skills and build a strong profile.
                        </Paragraph>
                    </CardBox>
                    <CardBox>
                        <Heading className="second">
                            <span className="small-title">
                                While
                                <span className="bg two"></span>
                            </span>
                            In College
                        </Heading>
                        <Paragraph>
                        Work part-time and get hands-on experience.
                        </Paragraph>
                    </CardBox>
                    <CardBox>
                        <Heading className="third">
                            <span className="small-title">
                                After
                                <span className="bg three"></span>
                            </span>
                            Graduation
                        </Heading>
                        <Paragraph>
                        Get placements right after graduation.
                        </Paragraph>
                    </CardBox>
                </CardsContainer>
            </WrapperBox>
        </MainContainer>
    );
}

export default Placement;

const MainContainer = styled.section`
    background-color: #fff;
    padding: 150px 100px;
    @media all and (max-width: 980px) {
        padding: 80px 0;
    }
    @media all and (max-width: 640px) {
        padding: 70px 0;
    }
    @media all and (max-width: 480px) {
        padding: 45px 0;
    }
`;
const WrapperBox = styled.section`
    text-align: center;
    width: 90%;
    margin: 0 auto;
`;
const Title = styled.h3`
    font-size: 28px;
    font-family: "gordita_bold";
    color: #32bcad;
    margin-bottom: 15px;
    @media all and (max-width: 1480px) {
        font-size: 26px;
    }
    @media all and (max-width: 680px) {
        font-size: 24px;
    }
    @media all and (max-width: 550px) {
        font-size: 22px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 5px;
        font-size: 20px;
    }
    @media all and (max-width: 360px) {
        font-size: 18px;
    }
`;
const MainHeading = styled.h1`
    font-size: 45px;
    font-family: "gordita_medium";
    margin-bottom: 15px;
    color: #0a0a0a;
    span {
        font-size: 45px;
        font-family: "gordita_medium";
        color: #32bcad;
        @media all and (max-width: 1480px) {
            font-size: 40px;
        }
        @media all and (max-width: 980px) {
            font-size: 36px;
        }
        @media all and (max-width: 680px) {
            font-size: 30px;
        }
        @media all and (max-width: 550px) {
            font-size: 28px;
        }
        @media all and (max-width: 480px) {
            font-size: 24px;
        }
        @media all and (max-width: 360px) {
            font-size: 23px;
        }
    }
    @media all and (max-width: 1480px) {
        font-size: 40px;
    }
    @media all and (max-width: 980px) {
        font-size: 36px;
    }
    @media all and (max-width: 680px) {
        font-size: 30px;
    }
    @media all and (max-width: 550px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 23px;
    }
`;
const Description = styled.p`
    font-size: 18px;
    margin-bottom: 80px;
    color: #7e7e7e;

    @media all and (max-width: 980px) {
        font-size: 16px;
        margin-bottom: 50px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }

    @media all and (max-width: 480px) {
        margin-bottom: 30px;
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1340px) {
        justify-content: space-between;
    }
    @media all and (max-width: 780px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;
const CardBox = styled.div`
    width: 31%;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    padding: 80px 40px;

    @media all and (max-width: 1340px) {
        padding: 80px 10px;
    }
    @media all and (max-width: 980px) {
        padding: 60px 40px;
    }
    @media all and (max-width: 780px) {
        width: 98%;
        margin-top: 20px;
        &:first-child {
            margin-top: 0;
        }
    }
    @media all and (max-width: 480px) {
        margin-top: 30px;
        padding: 30px;
    }
`;
const Heading = styled.h2`
    width: 81%;
    margin: 0 auto;
    font-size: 26px;
    font-family: "gordita_medium";
    margin-bottom: 25px;
    @media all and (max-width: 1280px) {
        font-size: 20px;
    }
    & span.small-title {
        font-family: "gordita_regular";
        margin-right: 10px;
        position: relative;
        font-size: 28px;
        z-index: 2;
        @media all and (max-width: 1280px) {
            font-size: 20px;
        }
        @media all and (max-width: 480px) {
            font-size: 22px;
        }
    }
    span.bg {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        z-index: -1;
        bottom: 18px;
        &.one {
            background-color: #fff2f3;
            /* left: 16%; */
            bottom: 25%;
            left: -40%;
            @media all and (max-width: 1280px) {
                width: 30px;
                height: 30px;
            }
            @media all and (max-width: 480px) {
                left: -87%;
            }
            @media all and (max-width: 400px) {
                left: -67%;
            }
            @media all and (max-width: 360px) {
                left: -89%;
            }
        }
        &.two {
            background-color: #f9f8e6;
            left: -11%;
            bottom: 27%;
            @media all and (max-width: 1280px) {
                width: 30px;
                height: 30px;
            }
            @media all and (max-width: 540px) {
                left: -13%;
            }
            @media all and (max-width: 480px) {
                left: -25%;
            }
            @media all and (max-width: 400px) {
                left: -24%;
            }
            @media all and (max-width: 360px) {
                left: -29%;
            }
        }
        &.three {
            background-color: #e5faf3;
            left: -25%;
            bottom: 24%;
            @media all and (max-width: 1280px) {
                width: 30px;
                height: 30px;
            }
            @media all and (max-width: 540px) {
                left: -21%;
            }
            @media all and (max-width: 480px) {
                left: -39%;
            }
            @media all and (max-width: 400px) {
                left: -33%;
            }
            @media all and (max-width: 360px) {
                left: -32%;
            }
        }
    }
    &.first {
        color: #ff7680;
        & .small-title {
            color: #ff7680;
        }
    }
    &.second {
        color: #e9cd3d;
        & .small-title {
            color: #e9cd3d;
        }
    }
    &.third {
        color: #09bf89;
        & .small-title {
            color: #09bf89;
        }
    }

    @media all and (max-width: 1080px) {
        width: 92%;
    }
    @media all and (max-width: 980px) {
        width: 79%;
    }
    @media all and (max-width: 890px) {
        width: 83%;
    }
    @media all and (max-width: 840px) {
        width: 93%;
    }
    @media all and (max-width: 800px) {
        width: 96%;
        flex-direction: column;
    }
    @media all and (max-width: 780px) {
        width: 42%;
    }
    @media all and (max-width: 720px) {
        width: 50%;
    }
    @media all and (max-width: 640px) {
        width: 53%;
    }
    @media all and (max-width: 600px) {
        width: 58%;
    }
    @media all and (max-width: 550px) {
        width: 68%;
    }
    @media all and (max-width: 480px) {
        width: 77%;
        font-size: 25px;
    }
    @media all and (max-width: 400px) {
        width: 84%;
    }
    @media all and (max-width: 360px) {
        width: 98%;
    }
`;
const Paragraph = styled.p`
    width: 75%;
    margin: 10px auto 0;
    color: #747474;
    font-size: 18px;
    @media all and (max-width: 1280px) {
        font-size: 16px;
    }

    @media all and (max-width: 1020px) {
        width: 100%;
        font-size: 16px;
    }
    @media all and (max-width: 900px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
        width: 100%;
    }
    @media all and (max-width: 360px) {
    }
`;
