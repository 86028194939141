import React, { useState } from "react";
import Slider from "react-slick";
import styled, { keyframes } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OtpInput from "react-otp-input";
import CountryModal from "../modals/CountryModal";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../contexts/authSlice";
import Lottie from "react-lottie";
import button_loader from "../../../assets/lotties/button_loader.json";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useApi } from "../../hooks/useApi";
// import OtpInput from "../OtpInput";


function LoginModal({ isLoginModal, setLoginModal }) {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [campus, setCampus] = useState({});
    const [associatedColleges, setAssociatedColleges] = useState([]);
    const [showCollegesModal, setShowCollegesModal] = useState(false);
    const [course, setCourse] = useState("");
    const [semester, setSemester] = useState(null);
    const [mailError, setMailError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [currentSection, setCurrentSection] = useState(1);
    const [count, setCount] = useState(1);
    const [error, setError] = useState(false);
    const [showCountryModal, setShowCountryModal] = useState(false);
    const [otp, setOtp] = useState("");
    const [focusOtp, setFocus] = useState(true)
    // const [fetchedOtp, setFetched] = useState("");
    const [semesterError, setSemesterError] = useState(false);

    const [isPhoneValid, setPhoneValid] = useState(false);
    const [isNameValid, setNamevalid] = useState(false);
    const [isLastNameValid, setLastNamevalid] = useState(false);
    const [isMailValid, setMailValid] = useState(false);
    const [isCollegeValid, setCollegeValid] = useState(false);
    const [isValidCourses, setValidCourses] = useState(false);
    const [isValidSem, setValidSem] = useState(false);

    const [isLoginError, setLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const [resendActive, setResendActive] = useState(false);
    const [resendCount, setResendCount] = useState(30);
    const [activeOtp, setActiveOtp] = useState(false)

    const apiConfig = useApi();

    // auth states
    const [auth, setAuth] = useState({});
    const [access, setAccess] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: button_loader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const items = [
        {
            id: 1,
            title: " We know the way to success",
            description:
                "Overpay help you set saving goals, earn cash back offers, Go to disclamer for more details and get paybacks up to two days early. Get $20 bonus when you receive qualifying direct deposits",
        },
        {
            id: 2,
            title: " Find your dream job here",
            description:
                "Overpay help you set saving goals, earn cash back offers, Go to disclamer for more details and get paybacks up to two days early. Get $20 bonus when you receive qualifying direct deposits",
        },
        {
            id: 3,
            title: " Find a better work in your way",
            description:
                "Overpay help you set saving goals, earn cash back offers, Go to disclamer for more details and get paybacks up to two days early. Get $20 bonus when you receive qualifying direct deposits",
        },
    ]

    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchCampuses = () => {
        apiConfig
            .get("/campuses/")
            .then((response) => {
                const { data, statusCode } = response.data;
                console.log(data.data);
                if (statusCode === 6000) {
                    setAssociatedColleges(data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        document.body.style.overflow = "hidden";

        fetchCampuses();

        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    console.log(resendActive, "resendActive");

    const resendCountHandler = () => {
        let count = 30;
        resendOtphandler();

        const resendIntervel = setInterval(() => {
            count -= 1;
            setResendCount(count);

            if (count === 0) {
                clearInterval(resendIntervel);
                setResendActive(true);
                setResendCount(30);
            }
        }, 1000);
    };

    useEffect(() => {
        let count = 30;
        console.log('useEffect reiggered');

        const resendIntervel = setInterval(() => {
            console.log('setInterval triggered');
            if (activeOtp) {
                count -= 1;
                setResendCount(count); 

                if (count === 0) {
                    clearInterval(resendIntervel);
                    setActiveOtp(false)
                    setResendActive(true);
                    setResendCount(30);
                }
            }
        }, 1000);

        if (!activeOtp) {
            clearInterval(resendIntervel);
        }

        return () => {
            clearInterval(resendIntervel);
        }
    }, [activeOtp])

    useEffect(() => {
        setTimeout(() => {
            setLoginError(false);
        }, 3000);
    }, [isLoginError]);



    // country modal
    const [code, setCode] = useState(false);
    const [, setCountry] = useState("");

    const [selected, setSelected] = useState({
        country_code: "IND",
        flag: "https://d38z36hay4oql7.cloudfront.net/media/countries/flags/india.png",
        name: "India",
        phone_code: "+91",
        phone_number_length: 10,
        web_code: "IN",
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        cssEase: "linear",
        autoplaySpeed: 5000,
    };
    const totalSection = 8;

    // post api to send otp to users phone number
    const sendOTP = () => {
        setIsLoading(true);
        const params = {
            country: selected.web_code,
            phone,
        };
        apiConfig
            .post("/users/join/", params)
            .then((response) => {
                const { data, statusCode } = response.data;
                console.log(data);
                console.log(statusCode);
                if (statusCode === 6000) {
                    // setFetched(data.otp);
                    setCurrentSection(currentSection + 1);
                    setCount(count + 1);
                    setIsLoading(false);
                    setFocus(false)
                    setActiveOtp(true)
                } else {
                    setError(true);
                    setErrorMessage(data.message);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    // post api for otp verification
    const verifyOTP = () => {
        setIsLoading(true);
        const params = {
            country: selected.web_code,
            phone,
            otp,
            // otp:`${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}`,
        };
        apiConfig
            .post("/users/join/verify/", params)
            .then((response) => {
                console.log(response.data);
                const { data, statusCode } = response.data;

                if (statusCode === 6000) {
                    const authDetails = {
                        phone: data.phone,
                        access: data.access,
                        refresh: data.refresh,
                        isAuthenticated: true,
                        name: data.name,
                        lastName: data.last_name,
                        campusId: data.campus_id,
                    };
                    setAuth(authDetails);

                    if (data.is_verified) {
                        dispatch(authActions.login({ ...authDetails }));
                        navigate("/dashboard/");
                    }
                    setAccess(data.access);
                    setCurrentSection(currentSection + 1);
                    setCount(count + 1);
                    setError(false);
                    setErrorMessage("");
                    setIsLoading(false);
                } else {
                    setError(true);
                    setErrorMessage(data.message);
                    setIsLoading(false);
                    setOtp("");
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    // post api for creating user profile
    const createProfile = () => {
        if (semester) {
            setIsLoading(true);
            const params = {
                first_name: name,
                last_name: lastName,
                email,
                campus: campus.id,
                course,
                semester,
            };
            const config = {
                headers: {
                    authorization: `Bearer ${access}`,
                },
            };
            apiConfig
                .post("/users/profile/update/", params, config)
                .then((response) => {
                    console.log(response.data);

                    const { data, statusCode } = response.data;
                    console.log(data);

                    if (statusCode === 6000) {
                        setIsLoading(false);
                        const campusId = data.campus_id;
                        dispatch(
                            authActions.login({
                                ...auth,
                                name,
                                lastName,
                                campusId,
                            })
                        );
                        navigate("/dashboard/");
                    } else {
                        setIsLoading(false);
                        setLoginError(true);
                        setLoginErrorMessage(data.message);
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                    setIsLoading(false);
                });
        } else {
            setSemesterError(true);
            setErrorMessage("Enter semester");
        }
    };

    const resendOtphandler = () => {
        const params = {
            country: selected.web_code,
            phone,
        };
        apiConfig
            .post("/users/join/resend/otp/", params)
            .then((response) => {
                console.log(response.data);
                const { data, statusCode } = response.data;

                if (statusCode === 6000) {
                    // setFetched(data.otp);
                } else {
                    console.log(data);
                    setError(true);
                    setErrorMessage(data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const validateMail = () => {
        apiConfig
            .post("/users/validate-email/", { email }, {
                headers: {
                    Authorization: `Bearer ${auth.access}`
                }
            })
            .then((response) => {
                const { data, statusCode } = response.data;
                console.log(data);

                if (statusCode === 6000) {
                    let isEmailExists = data.is_email_exists;
                    if (!isEmailExists) {
                        setMailValid(true);
                        setCurrentSection(currentSection + 1);
                        setCount(count + 1);
                        setError(false);
                    } else {
                        setMailError(true);
                        setErrorMessage("Email already exists");
                    }
                } else {
                    setError(true);
                    setErrorMessage(data.message);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    // submit button
    function nextClick() {

        if (currentSection < totalSection) {

            if (currentSection === 1) {

                if (phone.length === selected.phone_number_length) {
                    sendOTP();
                    setResendActive(false);
                } else {
                    setError(true);
                    setErrorMessage("Please enter your mobile number");
                }
            } else if (currentSection === 2) {
                const newOtp = otp

                if (newOtp.trim().length === 4) {
                    verifyOTP();
                    setActiveOtp(false)
                } else {

                    if (newOtp.trim().length === 0) {
                        setError(true);
                        setErrorMessage("please enter the otp");
                    } else {
                        setError(true);
                        setErrorMessage("Enter valid otp");
                    }
                }
            } else if (currentSection === 3) {
                if (name) {
                    setCurrentSection(currentSection + 1);
                    setCount(count + 1);
                    setError(false);
                    setErrorMessage("");
                } else {
                    setError(true);
                    setErrorMessage("Enter First Name");
                }
            } else if (currentSection === 4) {
                if (lastName) {
                    setCurrentSection(currentSection + 1);
                    setCount(count + 1);
                    setError(false);
                    setErrorMessage("");
                } else {
                    setError(true);
                    setErrorMessage("Enter Last name");
                }
            } else if (currentSection === 5) {
                if (
                    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(email) &&
                    email.includes(".com")
                ) {
                    setMailError(false);
                    setErrorMessage("");

                    validateMail();
                } else {
                    setMailError(true);
                    setErrorMessage("Enter a valid email");
                }
            } else if (currentSection === 6) {
                if (campus.id) {
                    setCurrentSection(currentSection + 1);
                    setCount(count + 1);
                    setError(false);
                    setErrorMessage("");
                } else {
                    setError(true);
                    setErrorMessage("Select college");
                }
            } else if (currentSection === 7) {
                if (course) {
                    setCurrentSection(currentSection + 1);
                    setCount(count + 1);
                    setError(false);
                    setErrorMessage("");
                } else {
                    setError(true);
                    setErrorMessage("Enter Course");
                }
            } else if (currentSection === 8) {
                if (semester) {
                    setCurrentSection(currentSection + 1);
                    setCount(count + 1);
                    setError(false);
                    setErrorMessage("");
                } else {
                    setError(true);
                    setErrorMessage("Enter Semester");
                }
            }
        }
    }
    useEffect(() => {
        setTimeout(() => {
            setError(false);
        }, 3000);
    }, [error]);
    const semesters = [
        {
            id: 1,
            title: 1,
        },
        {
            id: 2,
            title: 2,
        },
        // {
        //     id: 3,
        //     title: 3,
        // },
        // {
        //     id: 4,
        //     title: 4,
        // },
        // {
        //     id: 5,
        //     title: 5,
        // },
        // {
        //     id: 6,
        //     title: 6,
        // },
        // {
        //     id: 7,
        //     title: 7,
        // },
        // {
        //     id: 8,
        //     title: 8,
        // },
        // {
        //     id: 9,
        //     title: 9,
        // },
        // {
        //     id: 10,
        //     title: 10,
        // },
    ];

    // back button
    const backClick = () => {
        if (currentSection !== 3) {
            if (currentSection > 1) {
                setCurrentSection(currentSection - 1);
                setCount(count - 1);
            }
        }
        if (currentSection === 2) {
            setActiveOtp(false)
            setOtp("");
        }
    };
    // otp
    const handleChange = (otp) => {
        setOtp(otp);
    };
    // enter phone number
    const onPhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        const value = e.target.value;
        if (value === "" || re.test(value)) {
            setPhone(value);
        }
        if (value.length === selected.phone_number_length) {
            setPhoneValid(true);
        } else {
            setPhoneValid(false);
        }
    };
    const onSemesterChanges = () => {
        setSemesterError(false);
    };
    //  enter name
    const onNameChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^A-Za-z, " "]/gi, "");
        setName(value);
        if (value.length >= 3) {
            setNamevalid(true);
        } else {
            setNamevalid(false);
        }
    };

    //  enter last name
    const onLastNameChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^A-Za-z, " "]/gi, "");
        setLastName(value);

        if (value.length >= 3) {
            setLastNamevalid(true);
        } else {
            setLastNamevalid(false);
        }
    };

    //  enter email
    const onEmailChange = (e) => {
        let str = e.target.value;
        let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        setEmail(str);
        setMailError(regex.test(str));
        setMailValid(regex.test(str));

    };

    // enter course
    const onCourseChange = (e) => {
        let value = e.target.value;
        // value = value.replace(/[^A-Za-z, " "]/gi, "");
        setCourse(value);
        if (value.length > 2) {
            setValidCourses(true);
        }
    };

    const onSelectHandler = (selected) => {
        setSelected(selected);
    };
    const handleShow = () => {
        setCountry((prevValue) => !prevValue);
        setShowCountryModal(false);
    };

    return (
        <>
            <CountryModal
                code={code}
                setCode={setCode}
                selectedCountry={selected}
                setSelected={setSelected}
                setCountry={setCountry}
                show={showCountryModal}
                handleClick={handleShow}
                onSelectHandler={onSelectHandler}
            />
            {
                <Container>
                    <Overlay>
                        <Section>
                            <CloseBorderBox
                                onClick={() => {
                                    setLoginModal(false);
                                }}
                            >
                                <CloseContainer>
                                    <img
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/close-circle.svg"
                                        }
                                        alt="Close"
                                    />
                                </CloseContainer>
                            </CloseBorderBox>
                            <Left>
                                <ImageSection>
                                    <Img
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/login-background.png"
                                        }
                                        alt="image"
                                    />
                                </ImageSection>

                                <BottomSection>
                                    <Slider {...settings}>
                                        {items.map((list) => (
                                            <Contents key={list.id}>
                                                <Title>{list.title}</Title>
                                                <Description>
                                                    {list.description}
                                                </Description>
                                            </Contents>
                                        ))}
                                    </Slider>
                                </BottomSection>
                            </Left>
                            <Right>
                                <RoundSection>
                                    <img
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/round.png"
                                        }
                                        alt="round"
                                    />
                                </RoundSection>
                                <HeadingSection>
                                    <LogoContainer>
                                        <div className="logo-wrapper">
                                            <img
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/jobified-logo-svg.svg"
                                                }
                                                alt="Logo"
                                            />
                                        </div>
                                    </LogoContainer>
                                </HeadingSection>
                                <InputSection>
                                    <VisibleSection>
                                        <FormSections
                                            currentSection={currentSection}
                                        >
                                            <DetailBox className="name-section">
                                                <Heading>Hello 👋🏻</Heading>
                                                <Paragraph>
                                                    Let’s start with your phone
                                                    number{" "}
                                                </Paragraph>
                                                <UserInputs
                                                    className={
                                                        isPhoneValid
                                                            ? "valid-phone"
                                                            : ""
                                                    }
                                                >
                                                    <CountryContainer
                                                        bg={selected.flag}
                                                        onClick={() =>
                                                            setShowCountryModal(
                                                                true
                                                            )
                                                        }
                                                    />
                                                    <DropDownSection
                                                        onClick={() =>
                                                            setShowCountryModal(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/dropdown.svg"
                                                            }
                                                            alt="dropdown"
                                                        />
                                                    </DropDownSection>
                                                    <CountryCode>
                                                        {selected.phone_code}
                                                    </CountryCode>
                                                    <InputField
                                                        className="phone_field"
                                                        type="number"
                                                        placeholder="Enter phone number"
                                                        value={phone}
                                                        onChange={onPhoneChange}
                                                        tabIndex="-1"
                                                    />
                                                </UserInputs>
                                                {error ? (
                                                    <p className="error">
                                                        {errorMessage}
                                                    </p>
                                                ) : null}
                                            </DetailBox>
                                            <DetailBox tabIndex="-1">
                                                <Heading>
                                                    Enter the verification code
                                                </Heading>
                                                <Paragraph className="opt-description">
                                                    {" "}
                                                    We have send you a
                                                    verification code to your{" "}
                                                    <span>
                                                        {selected.phone_code}{" "}
                                                        {phone}{" "}
                                                    </span>
                                                    {/* &nbsp;&nbsp; otp -{" "}
                                                    {fetchedOtp} */}
                                                </Paragraph>
                                                <OtpContainer
                                                    tabIndex="-1"
                                                    id="otp_container"
                                                    style={focusOtp ? { visibility: "hidden" } : {}}
                                                >
                                                    <OtpInput
                                                        className="otp-field"
                                                        value={otp}
                                                        onChange={(value) => {
                                                            handleChange(value);
                                                        }}
                                                        numInputs={4}
                                                        isInputNum={true}
                                                        errorStyle={{
                                                            borderBottom:
                                                                "1px solid red",
                                                        }}
                                                        tabIndex="-1"
                                                    />
                                                </OtpContainer>
                                                <ResendContainer>
                                                    <span>
                                                        Resend Code? &nbsp;
                                                    </span>
                                                    {resendActive ? (
                                                        <span
                                                            className="resend"
                                                            onClick={(e) => {
                                                                setResendActive(
                                                                    false
                                                                );
                                                                setActiveOtp(true)
                                                                // resendCountHandler()
                                                            }}
                                                        >
                                                            resend
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className="resend"
                                                        >
                                                            {`${resendCount} sec`}
                                                        </span>
                                                    )}
                                                </ResendContainer>
                                                {error ? (
                                                    <p className="otp-error">
                                                        {errorMessage}
                                                    </p>
                                                ) : null}
                                            </DetailBox>
                                            <DetailBox className="enter-name">
                                                <Heading>
                                                    Enter your first name
                                                </Heading>
                                                <InputContainer
                                                    className={
                                                        isNameValid
                                                            ? "valid-name"
                                                            : ""
                                                    }
                                                >
                                                    <InputField
                                                        type="text"
                                                        placeholder="First name"
                                                        className="name"
                                                        value={name}
                                                        onChange={onNameChange}
                                                        onKeyDown={(e) => {

                                                            if (e.code === "Space") {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        tabIndex="-1"
                                                    />
                                                </InputContainer>
                                                {error ? (
                                                    <p className="name-error">
                                                        {errorMessage}
                                                    </p>
                                                ) : null}
                                            </DetailBox>
                                            <DetailBox>
                                                <Heading>
                                                    And your last name
                                                </Heading>
                                                <InputContainer
                                                    className={
                                                        isLastNameValid
                                                            ? "valid-name"
                                                            : ""
                                                    }
                                                >
                                                    <InputField
                                                        type="text"
                                                        placeholder="Last name"
                                                        value={lastName}
                                                        onChange={
                                                            onLastNameChange
                                                        }
                                                        className="name"
                                                        onKeyDown={(e) => {

                                                            if (e.code === "Space") {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        tabIndex="-1"
                                                    />
                                                </InputContainer>
                                                {error ? (
                                                    <p className="name-error">
                                                        {errorMessage}
                                                    </p>
                                                ) : null}
                                            </DetailBox>
                                            <DetailBox>
                                                <Heading>
                                                    Enter your e-mail
                                                </Heading>
                                                <Paragraph>
                                                    What email address can we
                                                    reach you at ? This is only
                                                    to get in touch, not to send
                                                    spam.
                                                </Paragraph>
                                                <InputContainer
                                                    className={[
                                                        isMailValid
                                                            ? "valid-email"
                                                            : "",
                                                        "email-address",
                                                    ]}
                                                >
                                                    <InputField
                                                        className="email"
                                                        type="email"
                                                        placeholder="Enter email"
                                                        value={email}
                                                        onChange={onEmailChange}
                                                        // onKeyPress={(e) =>
                                                        //     checkKeyDown(e)
                                                        // }
                                                        tabIndex="-1"
                                                    />
                                                </InputContainer>
                                                {errorMessage && mailError && (
                                                    <Error>
                                                        {errorMessage}
                                                    </Error>
                                                )}
                                            </DetailBox>
                                            <DetailBox>
                                                <Heading>
                                                    Enter your college
                                                </Heading>
                                                <Paragraph>
                                                    Which college are you
                                                    studying in ?
                                                </Paragraph>
                                                <CollegesContainer
                                                    id="campus-modal-ref"
                                                    onClick={(e) =>
                                                        setShowCollegesModal(
                                                            !showCollegesModal
                                                        )
                                                    }
                                                    className={[
                                                        showCollegesModal
                                                            ? "active"
                                                            : "",
                                                        isCollegeValid
                                                            ? "valid-college"
                                                            : "",
                                                    ]}
                                                >
                                                    <span>
                                                        {campus.name
                                                            ? `${campus.name}, ${campus.district}`
                                                            : "Select campus"}
                                                    </span>
                                                    <img
                                                        src={
                                                            require("../../../assets/images/dashboard/Vector.svg")
                                                                .default
                                                        }
                                                        alt=""
                                                    />
                                                </CollegesContainer>
                                                {error ? (
                                                    <p className="name-error">
                                                        {errorMessage}
                                                    </p>
                                                ) : null}
                                            </DetailBox>
                                            <DetailBox>
                                                <Heading>
                                                    Enter your course
                                                </Heading>
                                                <Paragraph>
                                                    What course are you
                                                    pursuing?
                                                </Paragraph>
                                                <InputContainer
                                                    className={
                                                        isValidCourses
                                                            ? "valid-email"
                                                            : ""
                                                    }
                                                >
                                                    <InputField
                                                        type="text"
                                                        placeholder="Course"
                                                        value={course}
                                                        onChange={
                                                            onCourseChange
                                                        }
                                                        // onKeyPress={(e) =>
                                                        //     checkKeyDown(e)
                                                        // }
                                                        tabIndex="-1"
                                                    />
                                                </InputContainer>
                                                {error ? (
                                                    <p className="name-error">
                                                        {errorMessage}
                                                    </p>
                                                ) : null}
                                            </DetailBox>
                                            <DetailBox>
                                                <Heading>
                                                    Enter your semester
                                                </Heading>
                                                <Paragraph>
                                                    Which semester are you in?
                                                </Paragraph>

                                                <SemesterContainer
                                                    id="semester-ref"
                                                    onClick={(e) =>
                                                        setShowModal(!showModal)
                                                    }
                                                    className={[
                                                        showModal
                                                            ? "active"
                                                            : "",
                                                        isValidSem
                                                            ? "valid-sem"
                                                            : "",
                                                    ]}
                                                >
                                                    <span>
                                                        {semester
                                                            ? semester
                                                            : "Select semester"}
                                                    </span>
                                                    <img
                                                        src={
                                                            require("../../../assets/images/dashboard/Vector.svg")
                                                                .default
                                                        }
                                                        alt=""
                                                    />
                                                </SemesterContainer>
                                                {semesterError && (
                                                    <p className="error semester-error">
                                                        {errorMessage}
                                                    </p>
                                                )}
                                            </DetailBox>
                                        </FormSections>
                                    </VisibleSection>
                                    <CollegeModalWrapper>
                                        {showCollegesModal && (
                                            <CampusModal
                                                associatedColleges={
                                                    associatedColleges
                                                }
                                                setCollegeValid={
                                                    setCollegeValid
                                                }
                                                setCampus={setCampus}
                                                setShowCollegesModal={
                                                    setShowCollegesModal
                                                }
                                            />
                                        )}
                                        {showModal && (
                                            <SemModal
                                                onSemesterChanges={
                                                    onSemesterChanges
                                                }
                                                semesters={semesters}
                                                setSemester={setSemester}
                                                setShowModal={setShowModal}
                                                setValidSem={setValidSem}
                                                showModal={showModal}
                                            />
                                        )}
                                    </CollegeModalWrapper>
                                </InputSection>
                                <BottomContainer>
                                    <PageCount>
                                        <span>0{count}</span>
                                        <Total>/08</Total>
                                    </PageCount>
                                    <div>
                                        {isLoginError && (
                                            <ErrorMessage>
                                                {loginErrorMessage}
                                            </ErrorMessage>
                                        )}
                                        <ButtonContainer>
                                            {currentSection !== 1 &&
                                                currentSection !== 3 && (
                                                    <BackButton
                                                        onClick={backClick}
                                                    >
                                                        Back
                                                    </BackButton>
                                                )}
                                            <NextButton
                                                className={[
                                                    currentSection === 8 &&
                                                    "hide",
                                                    isLoading ? "loading" : "",
                                                ]}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    nextClick();
                                                }}
                                            >
                                                {!isLoading ? (
                                                    "Next"
                                                ) : (
                                                    <Lottie
                                                        options={lottieOptions}
                                                        width={23}
                                                        height={23}
                                                    />
                                                )}
                                            </NextButton>
                                            {currentSection === 8 && (
                                                <SubmitButton
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        createProfile();
                                                    }}
                                                    className={
                                                        isLoading
                                                            ? "loading"
                                                            : ""
                                                    }
                                                >
                                                    {!isLoading ? (
                                                        "Submit"
                                                    ) : (
                                                        <Lottie
                                                            options={
                                                                lottieOptions
                                                            }
                                                            width={23}
                                                            height={23}
                                                        />
                                                    )}
                                                </SubmitButton>
                                            )}
                                        </ButtonContainer>
                                    </div>
                                </BottomContainer>
                            </Right>
                        </Section>
                    </Overlay>
                </Container>
            }
        </>
    );
}

const CampusModal = ({
    associatedColleges,
    setCampus,
    setShowCollegesModal,
    setCollegeValid,
}) => {
    const parentRef = document.getElementById("campus-modal-ref");
    const modalRef = useOutsideClick(() => {
        setShowCollegesModal(false);
    }, parentRef);

    return (
        <CollegesModal className="active" ref={modalRef}>
            {associatedColleges.map((college) => (
                <li
                    key={college.id}
                    onClick={(e) => {
                        setCampus(college);
                        setShowCollegesModal(false);
                        setCollegeValid(true);
                    }}
                >
                    {`${college.name}, ${college.location}, ${college.district}`}
                </li>
            ))}
        </CollegesModal>
    );
};

const SemModal = ({
    showModal,
    semesters,
    onSemesterChanges,
    setValidSem,
    setSemester,
    setShowModal,
}) => {
    const parentRef = document.getElementById("semester-ref");
    const handler = () => {
        setShowModal(false);
    };

    const modalRef = useOutsideClick(handler, parentRef);

    return (
        <SemesterModal ref={modalRef} className={showModal ? "active" : ""}>
            {semesters.map((semester) => (
                <li
                    key={semester.id}
                    onClick={(e) => {
                        onSemesterChanges();
                        setValidSem(true);
                        setSemester(semester.title);
                        setShowModal(false);
                    }}
                >
                    {semester.title}
                </li>
            ))}
        </SemesterModal>
    );
};

export default LoginModal;

const ResendContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 160px;
    /* justify-content: flex-end; */

    span {
        font-size: 14px;

        &.resend {
            cursor: pointer;
            color: #32bcad;
        }
    }

    @media all and (max-width: 768px) {
        /* margin-right: 0; */
        margin-left: 68px;
    }
    @media all and (max-width: 720px) {
        margin-left: 68px;
    }
    @media all and (max-width: 680px) {
        /* margin-left: 130px; */
    }
`;

const CollegeModalWrapper = styled.div`
    position: relative;
`;
const ErrorMessage = styled.p`
    color: red;
    font-size: 14px;
    text-align: right;
    margin-bottom: 12px;
`;
const CollegesModal = styled.ul`
    position: absolute;
    top: -65px;
    left: 0;
    z-index: 10000;
    height: 0;
    transition: height 4s ease 2s;

    &.active {
        height: 110px;
    }
    width: 95%;
    border: 1px solid #adadad;
    border-radius: 12px;
    overflow-y: scroll;
    background: #fff;
    li {
        padding: 8px 16px;
        color: #484747;
        font-family: gordita_regular;
        font-size: 16px;
        :hover {
            background-color: rgb(240, 255, 244);
        }
        cursor: pointer;
    }
    @media all and (max-width: 980px) {
        top: -70px;
    }
    @media all and (max-width: 640px) {
        top: -56px;
    }
    @media all and (max-width: 480px) {
        top: -47px;
    }
    @media all and (max-width: 360px) {
        top: -58px;
    }
`;
const CollegesContainer = styled.div`
    position: relative;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 9px;
    color: #0a0a0a;
    border: 2px solid #adadad;
    margin: 16px 0;
    border-radius: 12px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        padding: 11px 9px;
    }
    &.valid-college {
        border: 2px solid #32bcad;
    }
    span {
        font-family: gordita_regular;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #484747;
    }
    img {
        width: 20px;
        transition: all 0.4s ease 0s;
    }
    &.active {
        img {
            transform: rotate(-180deg);
        }
    }
    @media all and (max-width: 660px) {
        span {
            font-size: 14px;
        }
        img {
            width: 18px;
        }
    }
`;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 500;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.3s;
`;
const Overlay = styled.div`
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Section = styled.div`
    animation-name: ${videoAnimation};
    animation-duration: 0.5s;
    width: 75%;
    margin: 0 auto;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    padding: 100px 0;
    position: relative;
    @media all and (max-width: 1280px) {
        width: 88%;
    }
    @media all and (max-width: 980px) {
        width: 92%;
    }
    @media all and (max-width: 640px) {
        flex-direction: column;
    }
    @media all and (max-width: 480px) {
        width: 93%;
    }
`;
const CloseBorderBox = styled.div`
    position: absolute;
    width: 45px;
    cursor: pointer;
    background: #fff;
    right: -15px;
    top: 85px;
    background: rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 1;
    border-radius: 50%;
    padding: 4px;
    @media all and (max-width: 1080px) {
        width: 40px;
        right: -10px;
        top: 86px;
    }
    @media all and (max-width: 768px) {
        width: 40px;
        right: -10px;
    }
    @media all and (max-width: 640px) {
        right: -9px;
        width: 38px;
        top: 93px;
    }
    @media all and (max-width: 480px) {
        right: -9px;
        top: 88px;
    }
    @media all and (max-width: 360px) {
        right: -10px;
        width: 34px;
        top: 94px;
    }
`;
const CloseContainer = styled.div`
    background-color: #fff;
    border-radius: 50%;
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #19244e;
    width: 40%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 70px 30px 80px 30px;
    @media all and (max-width: 1080px) {
        padding: 70px 10px 80px 10px;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        padding: 18px 20px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 25px;
    }
    @media all and (max-width: 480px) {
        padding: 5px 20px 19px;
    }

    @media all and (max-width: 360px) {
        padding: 12px 10px;
    }
`;
const ImageSection = styled.div`
    width: 355px;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 344px;
    }
    @media all and (max-width: 1080px) {
        width: 306px;
    }
    @media all and (max-width: 980px) {
        width: 291px;
    }
    @media all and (max-width: 768px) {
        width: 252px;
    }
    @media all and (max-width: 640px) {
        width: 174px;
    }
`;
const Img = styled.img``;
const BottomSection = styled.div`
    margin-top: 30px;
    width: 95% !important;
`;

const Title = styled.h3`
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-family: gordita_medium;
    @media all and (max-width: 980px) {
        font-size: 19px;
    }
    @media all and (max-width: 768px) {
        font-size: 18px;
    }
    @media all and (max-width: 480px) {
        font-size: 17px;
    }
`;
const Description = styled.p`
    font-size: 13px;
    text-align: center;
    margin-top: 16px;
    color: #fff;
    margin-bottom: 15px;
    @media all and (max-width: 480px) {
        font-size: 13px;
        margin-bottom: 8px;
    }
`;
const Right = styled.div`
    width: 60%;
    background: #fff;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 90px 70px 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    @media all and (max-width: 980px) {
        width: 55%;
    }
    @media all and (max-width: 980px) {
        padding: 80px 40px 60px;
    }
    @media all and (max-width: 768px) {
        padding: 70px 25px 50px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        padding: 18px 20px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 25px;
    }
    @media all and (max-width: 480px) {
        padding: 25px 20px;
    }
    @media all and (max-width: 360px) {
        padding: 16px 20px;
    }
`;
const RoundSection = styled.div`
    width: 200px;
    position: absolute;
    top: 0;
    right: 0;
    @media all and (max-width: 980px) {
        width: 135px;
    }
    @media all and (max-width: 480px) {
        width: 62px;
    }
`;
const HeadingSection = styled.div`
    @media all and (max-width: 640px) {
        margin-bottom: 30px;
    }
`;
const LogoContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-wrapper {
        width: 110px;
    }
    @media all and (max-width: 1280px) {
        width: 90%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        width: 265px;
    }
    @media all and (max-width: 480px) {
        width: 300px;
    }
    @media all and (max-width: 360px) {
        width: 262px;
    }
`;
const InputSection = styled.div``;

const Contents = styled.div``;
const Heading = styled.h4`
    color: #000000;
    font-family: gordita_medium;
    font-size: 20px;
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const InputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border: 2px solid #adadad;
    padding: 18px 12px;
    border-radius: 12px;
    &.valid-name,
    &.valid-name,
    &.valid-email {
        border-color: #32bcad;
    }
    @media all and (max-width: 980px) {
        padding: 14px 12px;
    }
    @media all and (max-width: 480px) {
        padding: 9px 12px;
    }
    &.email-address {
        position: relative;
        @media all and (max-width: 640px) {
            margin-top: 15px;
        }
    }
`;
const SemesterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border: 2px solid #adadad;
    padding: 20px 12px;
    border-radius: 12px;
    &.valid-sem {
        border-color: #32bcad;
    }
    @media all and (max-width: 360px) {
        padding: 7px 12px;
    }
    cursor: pointer;
    span {
        font-family: gordita_regular;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #484747;
    }
    img {
        width: 20px;
        transition: all 0.4s ease 0s;
    }
    &.active {
        img {
            transform: rotate(-180deg);
        }
    }
    @media all and (max-width: 660px) {
        span {
            font-size: 17px;
        }
        img {
            width: 18px;
        }
    }
`;
const SemesterModal = styled.ul`
    overflow-y: scroll;
    overflow-x: inherit;
    position: absolute;
    top: -48px;
    right: 0;
    z-index: 10000;
    height: 0;
    transition: height 4s ease 2s;

    &.active {
        height: 80px;
        /* height: auto; */
        @media all and (max-width: 1280px) {
            top: -42px;
            /* height: 100px; */
        }
        @media all and (max-width: 1080px) {
            top: -47px;
            /* height: 100px; */
        }
        @media all and (max-width: 768px) {
            top: -57px;
        }
        @media all and (max-width: 640px) {
            top: -217px;
            /* height: 120px; */
        }
        @media all and (max-width: 480px) {
            top: -217px;
            /* height: 130px; */
        }
        @media all and (max-width: 360px) {
            top: -226px;
            /* height: 130px; */
        }
    }
    width: 100px;
    border: 1px solid #adadad;
    border-radius: 6px;
    background: #fff;
    ::-webkit-scrollbar {
        display: block;
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1b2;
    }

    ::-webkit-scrollbar-thumb {
        background: #888888be;
        border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #5555559b;
    }

    @media all and (max-width: 1280px) {
        top: -69px;
    }

    @media all and (max-width: 1080px) {
        top: -73px;
        height: 134px;
    }
    @media all and (max-width: 768px) {
        top: -263px;
    }
    @media all and (max-width: 640px) {
        top: -242px;
    }

    li {
        padding: 8px 16px;
        color: #484747;
        font-family: gordita_regular;
        font-size: 16px;
        :hover {
            background-color: rgb(240, 255, 244);
        }
        cursor: pointer;
    }
`;
const UserInputs = styled.div`
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 18px;
    border: 2px solid #adadad;
    padding: 12px;
    border-radius: 12px;
    position: relative;

    &.valid-phone {
        border: 2px solid #32bcad;
    }

    @media all and (max-width: 1080px) {
        padding: 8px 12px;
    }

    @media all and (max-width: 360px) {
        padding: 8px 6px;
    }
`;
const CountryCode = styled.span`
    margin-right: 10px;
    font-size: 18px;
    font-family: "gordita_medium";
    @media all and (max-width: 768px) {
        margin-right: 4px;
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const CountryContainer = styled.div`
    margin-right: 4px;
    cursor: pointer;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: ${(props) => (props.bg ? `url(${props.bg})` : null)} center
        center no-repeat;
    background-size: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 50%;

        display: block;
        width: 45px;
        left: -10px;
    }
    @media all and (max-width: 360px) {
        margin-right: 6px;
    }
`;
const DropDownSection = styled.div`
    width: 12px;
    margin-right: 10px;
    cursor: pointer;
    img {
    }
`;

const InputField = styled.input`
    flex: 1;
    font-size: 18px;
    top: 12px;
    font-family: "gordita_medium";

    &.phone_filed {
        @media all and (max-width: 768px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 980px) {
        /* max-width: 63%; */
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
    &::placeholder {
        color: #adadad;
        font-family: "gordita_regular";
        font-size: 16px;
        @media all and (max-width: 768px) {
            font-size: 15px;
        }
        @media all and (max-width: 360px) {
            font-size: 13px;
        }
    }

    &.name{
        text-transform: capitalize;
    }
`;
const BottomContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        position: relative;
        transition: all 0.4s ease-in-out;
    }
`;
const PageCount = styled.div`
    font-family: gordita_medium;
    display: flex;
    justify-content: center;
    align-items: baseline;
    span {
        -webkit-text-stroke: 1px #747474;
        -webkit-text-fill-color: white;
        font-size: 55px;
        font-family: gordita_medium;
        @media all and (max-width: 1080px) {
            font-size: 50px;
        }
        @media all and (max-width: 980px) {
            font-size: 36px;
        }
        @media all and (max-width: 768px) {
            font-size: 30px;
        }
    }
`;
const Total = styled.h3`
    font-family: gordita_medium;
    color: #d4d4d4;
    font-size: 30px;
    @media all and (max-width: 980px) {
        font-size: 24px;
    }
    @media all and (max-width: 768px) {
        font-size: 20px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;
const BackButton = styled.span`
    box-shadow: 10px 14px 48px rgba(0, 0, 0, 0.1);
    border: 1px solid #adadad;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px 35px;
    color: #d4d4d4;
    text-align: center;
    font-family: gordita_medium;
    cursor: pointer;
    margin-right: 15px;
    transition: all 0.4 ease-in-out;

    @media alla and (max-width: 1080px) {
        padding: 12px 30px;
    }
    @media all and (max-width: 980px) {
        padding: 11px 28px;
        margin-right: 5px;
    }
    @media all and (max-width: 768px) {
        padding: 8px 24px;
        margin-right: 6px;
    }
    @media all and (max-width: 360px) {
        padding: 8px 19px;
        font-size: 14px;
    }
`;
const NextButton = styled.span`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    box-shadow: 10px 14px 48px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px 35px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-family: gordita_medium;
    &.hide {
        display: none;
    }
    &.loading {
        padding: 15px 43.5px;
    }
    @media alla and (max-width: 1080px) {
        padding: 12px 30px;

        &.loading {
            padding: 15px 43.5px;
        }
    }
    @media all and (max-width: 980px) {
        padding: 11px 28px;

        &.loading {
            padding: 11px 34px;
        }
    }
    @media all and (max-width: 768px) {
        padding: 8px 24px;

        &.loading {
            padding: 8px 29px;
        }
    }
    @media all and (max-width: 360px) {
        padding: 8px 19px;
        font-size: 14px;

        &.loading {
            padding: 8px 23px;
        }
    }
`;

const SubmitButton = styled.button`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    box-shadow: 10px 14px 48px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px 25px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-family: gordita_medium;
    width: 109px;

    &.loading {
        padding: 15px 43.5px;
    }
    @media alla and (max-width: 1080px) {
        padding: 12px 30px;

        &.loading {
            padding: 15px 43.5px;
        }
    }
    @media all and (max-width: 980px) {
        padding: 11px 17px;
        width: 97px;

        &.loading {
            padding: 12px 43.5px;
        }
    }
    @media all and (max-width: 768px) {
        padding: 8px 15px;
        width: 90px;

        &.loading {
            padding: 8px 43.5px;
        }
    }
    @media all and (max-width: 360px) {
        padding: 8px 5px;
        font-size: 14px;
        width: 75px;

        &.loading {
            padding: 6px 43.5px;
        }
    }
`;

const VisibleSection = styled.div`
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    @media all and (max-width: 768px) {
        height: 215px;
    }
    @media all and (max-width: 640px) {
        height: 190px;
    }
    @media all and (max-width: 480px) {
        height: 165px;
    }
    @media all and (max-width: 360px) {
        height: 178px;
        margin-bottom: 10px;
    }
`;

const FormSections = styled.form`
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    transition: 0.3s;
    transition-delay: 1s;
    transition: top 1s ease-in;
    scroll-behavior: smooth;
    top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-241px"
                : props.currentSection === 3
                    ? "-510px"
                    : props.currentSection === 4
                        ? "-757px"
                        : props.currentSection === 5
                            ? "-1024px"
                            : props.currentSection === 6
                                ? "-1274px"
                                : props.currentSection === 7
                                    ? "-1536px"
                                    : props.currentSection === 8
                                        ? "-1792px"
                                        : null};
    @media all and (max-width: 1280px) {
        top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-231px"
                : props.currentSection === 3
                    ? "-509px"
                    : props.currentSection === 4
                        ? "-767px"
                        : props.currentSection === 5
                            ? "-1028px"
                            : props.currentSection === 6
                                ? "-1283px"
                                : props.currentSection === 7
                                    ? "-1531px"
                                    : props.currentSection === 8
                                        ? "-1785px"
                                        : null};
    }
    @media all and (max-width: 1080px) {
        top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-248px"
                : props.currentSection === 3
                    ? "-512px"
                    : props.currentSection === 4
                        ? "-767px"
                        : props.currentSection === 5
                            ? "-1023px"
                            : props.currentSection === 6
                                ? "-1279px"
                                : props.currentSection === 7
                                    ? "-1535px"
                                    : props.currentSection === 8
                                        ? "-1791px"
                                        : null};
    }
    @media all and (max-width: 980px) {
        top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-245px"
                : props.currentSection === 3
                    ? "-509px"
                    : props.currentSection === 4
                        ? "-767px"
                        : props.currentSection === 5
                            ? "-1029px"
                            : props.currentSection === 6
                                ? "-1278px"
                                : props.currentSection === 7
                                    ? "-1534px"
                                    : props.currentSection === 8
                                        ? "-1790px"
                                        : null};
    }
    @media all and (max-width: 768px) {
        top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-351px"
                : props.currentSection === 3
                    ? "-570px"
                    : props.currentSection === 4
                        ? "-799px"
                        : props.currentSection === 5
                            ? "-1037px"
                            : props.currentSection === 6
                                ? "-1258px"
                                : props.currentSection === 7
                                    ? "-1487px"
                                    : props.currentSection === 8
                                        ? "-1720px"
                                        : null};
    }
    @media all and (max-width: 640px) {
        top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-344px"
                : props.currentSection === 3
                    ? "-585px"
                    : props.currentSection === 4
                        ? "-843px"
                        : props.currentSection === 5
                            ? "-1118px"
                            : props.currentSection === 6
                                ? "-1362px"
                                : props.currentSection === 7
                                    ? "-1622px"
                                    : props.currentSection === 8
                                        ? "-1870px"
                                        : null};
    }
    @media all and (max-width: 480px) {
        top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-321px"
                : props.currentSection === 3
                    ? "-572px"
                    : props.currentSection === 4
                        ? "-808px"
                        : props.currentSection === 5
                            ? "-1084px"
                            : props.currentSection === 6
                                ? "-1340px"
                                : props.currentSection === 7
                                    ? "-1590px"
                                    : props.currentSection === 8
                                        ? "-1851px"
                                        : null};
    }
    @media all and (max-width: 360px) {
        top: ${(props) =>
        props.currentSection === 1
            ? "0px"
            : props.currentSection === 2
                ? "-290px"
                : props.currentSection === 3
                    ? "-541px"
                    : props.currentSection === 4
                        ? "-808px"
                        : props.currentSection === 5
                            ? "-1086px"
                            : props.currentSection === 6
                                ? "-1333px"
                                : props.currentSection === 7
                                    ? "-1575px"
                                    : props.currentSection === 8
                                        ? "-1836px"
                                        : null};
    }
`;
const DetailBox = styled.span`
    position: relative;
    height: 200px;
    display: block;
    box-sizing: border-box;
    margin-bottom: 56px;
    & .otp input {
        &::placeholder {
            font-size: 50px;
        }
    }
    p.error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }
    p.otp-error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }
    p.name-error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
    }
    &.name-section {
        @media all and (max-width: 768px) {
            margin-bottom: 119px;
            margin-top: 30px;
        }
        @media all and (max-width: 480px) {
            margin-top: 0;
        }
    }
    @media all and (max-width: 768px) {
        margin-bottom: 85px;
        margin-bottom: 30px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 55px;
    }
    &.enter-name {
        p.name-error {
            color: red;
            font-size: 12px;
            margin-top: 5px;
        }
        @media all and (max-width: 640px) {
            margin-bottom: 60px;
        }
    }
`;
const OtpContainer = styled.div`
    @media all and (max-width: 1080px) {
        div {
            /* * {
                border-bottom: none !important;
            } */
            & > div.otp-field > input {
                border-bottom: 1px solid #808080 !important;
            }
        }
    }
`;
const Paragraph = styled.p`
    margin-top: 10px;
    font-size: 16px;
    color: #747474;
    font-family: "gordita_regular";
    span {
        font-size: 14px;
        font-family: "gordita_regular";
        color: #32bcad;
    }

    &.opt-description {
        margin-bottom: 13px;
        @media alla and (max-width: 1080px) {
            margin-bottom: 30px;
        }
        @media all and (max-width: 640px) {
            margin-bottom: 9px;
        }
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;

const Error = styled.span`
    color: red;
    font-size: 12px;
    position: absolute;

    @media all and (max-width: 480px) {
        bottom: 49px;
    }
    @media all and (max-width: 360px) {
        bottom: 31px;
    }
`;
