import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import VideoPlayer from "../../applications/video-player/src/VideoPlayer";
import useAuthApi from "../../hooks/useApi";

function VideoModal({ isModal, setModal, _item, items }) {
    const [item, setItem] = useState({});
    const authApi = useAuthApi();

    const videoJsOptions = {
        autoplay: true,
        controls: false,
    };

    useEffect(() => {
        authApi
            .get(`/orientations/videos/${_item}/`)
            .then((response) => {
                let data = response.data;

                if (data.statusCode === 6000) {
                    setItem(data.data);
                } else {
                    setItem(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        isModal && (
            <Container id="invite-video">
                <Overlay onClick={() => setModal(false)}></Overlay>
                <Section onClick={(e) => e.stopPropagation()}>
                    <VideoContainer className="player-wrapper">
                        <VideoPlayer
                            {...videoJsOptions}
                            source="https://player.vimeo.com/external/726890296.m3u8?s=c47b764dd33f1a05dd84d3374defbe168957d78b"
                        />
                    </VideoContainer>
                    <CloseContainer onClick={() => setModal(!isModal)}>
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/close.svg"
                            }
                            alt="close"
                        />
                    </CloseContainer>

                    <Title>{item?.title}</Title>
                    <Paragraph>{item?.description}</Paragraph>
                </Section>
            </Container>
        )
    );
}

export default VideoModal;
const videoAnimation = keyframes`
    0% { transform:scale(0,0); opacity:0; }
    100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 500;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
`;
const Section = styled.div`
    position: relative;
    border-radius: 9px;
    background-color: #fff;
    width: 50%;
    padding: 20px;
    animation-name: ${videoAnimation};
    animation-duration: 0.3s;
    z-index: 999;
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 1080px) {
    }
    @media all and (max-width: 980px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 85%;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        padding: 13px;
        width: 93%;
    }
    @media all and (max-width: 360px) {
    }
    @media all and (max-width: 320px) {
    }
`;
const VideoContainer = styled.div`
    position: relative;
    margin-bottom: 20px;
    border-radius: 10px;
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
    img {
        display: block;
        width: 100%;
        border-radius: 10px;
    }
`;
const Title = styled.h4`
    font-family: gordita_medium;
    color: #0a0a0a;
    font-size: 16px;
`;
const Paragraph = styled.p`
    color: #747474;
    font-family: gordita_regular;
    font-size: 14px;
`;
const CloseContainer = styled.div`
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: -18px;
    right: -18px;
    @media all and (max-width: 1280px) {
        width: 30px;
        top: -10px;
        right: -11px;
    }
    @media all and (max-width: 1080px) {
        right: -9px;
    }
    @media all and (max-width: 480px) {
        width: 28px;
    }
    @media all and (max-width: 360px) {
        right: -5px;
        top: -7px;
        width: 25px;
    }
`;
