import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

function Sidebar({ isMenu, setMenu }) {

    return (
        <>
            <Container type={isMenu}>
                <Section>
                    <List>
                        <ItemContainer
                            type={isMenu}
                            to="dashboard"
                            className={({ isActive }) =>
                                isActive ? "active" : ""
                            }
                        >
                            <LeftSection>
                                <img
                                    className="video-green"
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/element-3.svg"
                                    }
                                    alt="dashboard"
                                />
                                <img
                                    className="white"
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/dashboard-white.svg"
                                    }
                                    alt="white"
                                />
                            </LeftSection>
                            <Details type={isMenu} className="dashboard">
                                Dashboard
                            </Details>
                        </ItemContainer>

                        <ItemContainer
                            to="orientation-videos"
                            className={({ isActive }) =>
                                isActive ? "active" : ""
                            }
                        >
                            <LeftSection>
                                <img
                                    className="one"
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/video-square.svg"
                                    }
                                    alt="image_"
                                />
                                <img
                                    className="green"
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/white.svg"
                                    }
                                    alt="image_"
                                />
                            </LeftSection>
                            <Details className="video" type={isMenu}>
                                Orientation Videos
                            </Details>
                            {/* <img
                                type={isMenu}
                                className="drop-down"
                                src={
                                    require(""https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Vector.svg")
                                        .default
                                }
                                alt="drop-down"
                            /> */}
                        </ItemContainer>
                    </List>
                </Section>
            </Container>
        </>
    );
}

export default Sidebar;
const Container = styled.div`
    padding: 102px 13px;
    width: 293px;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    z-index: 10;
    border-right: 1px solid #eeeeee;
    background: #f8f8f8;
    -webkit-transition: 0.3s;
    transition: all 0.4s ease-in-out;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        padding: 94px 13px;
        width: ${({ type }) => (type ? "294px" : "80px")};
        &:hover {
            width: 294px;
        }
        &:hover h4 {
            display: block;
            margin-right: 0;
            margin-left: 10px;
        }
        &:hover .active {
            justify-content: flex-start;
        }
    }
    @media all and (max-width: 1080px) {
        padding: 94px 13px;
        width: ${({ type }) => (type ? "249px" : "0px")};
        &:hover {
            width: 249px;
        }
        &:hover h4 {
            display: block;
            margin-right: 0;
            margin-left: 10px;
        }
        padding-left: ${({ type }) => (type ? "10px" : "0px")};
        padding-right: ${({ type }) => (type ? "10px" : "0px")};
    }
    @media all and (max-width: 980px) {
        width: ${({ type }) => (type ? "225px" : "0px")};
        &:hover {
            width: 225px;
        }
    }

    @media all and (max-width: 640px) {
        width: ${({ type }) => (type ? "224px" : "0px")};
        padding-top: ${({ type }) => (type ? "92px" : "0px")};
        padding-left: ${({ type }) => (type ? "10px" : "0px")};
        padding-right: ${({ type }) => (type ? "10px" : "0px")};
    }
`;
const Section = styled.div``;
const List = styled.ul``;

const ItemContainer = styled(NavLink)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    color: #000;
    cursor: pointer;
    padding: 10px 25px;

    &:last-child {
        margin-bottom: 0;
    }
    &.active {
        display: flex;
        background-color: #2da99b;
        border-radius: 10px;
        align-items: center;
        color: #fff;
        cursor: pointer;

        @media all and (max-width: 1280px) {
            padding: 16px 11px;
        }

        @media all and (max-width: 768px) {
            padding: 10px 15px;
        }
    }
    @media all and (max-width: 1280px) {
        padding: 10px 11px;
        &.active {
            display: flex;
            background-color: #2da99b;
            border-radius: 10px;
            align-items: center;
            color: #fff;
            cursor: pointer;

            @media all and (max-width: 1280px) {
                padding: 16px 11px;
            }
            @media all and (max-width: 768px) {
                padding: 10px 15px;
            }
        }

        img.drop-down {
            width: 10px;
            display: none;
            @media all and (max-width: 768px) {
                display: ${(type) => (type ? "block" : "none")};
            }
        }
    }
`;
const LeftSection = styled.div`
    width: 20px;
    margin-right: 10px;
    @media all and (max-width: 1280px) {
        margin-right: 0;
    }
    @media all and (max-width: 1080px) {
        margin-right: 10px;
    }
`;
const Details = styled.h4`
    font-family: gordita_medium;
    font-size: 16px;
    @media all and (max-width: 1280px) {
        /* display: none; */
        &:hover {
            display: block;
        }
    }
    &.dashboard {
        @media all and (max-width: 1280px) {
            display: ${({ type }) => (type ? "block" : "none")};
        }
        @media all and (max-width: 1080px) {
            display: block;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
    &.video {
        margin-right: 30px;
        @media all and (max-width: 1280px) {
            display: ${({ type }) => (type ? "block" : "none")};
        }
        @media all and (max-width: 1080px) {
            margin-right: 9px;
        }
        @media all and (max-width: 980px) {
            margin-right: 3px;
        }
        @media all and (max-width: 768px) {
            margin-right: 5px;
        }
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
        display: ${({ type }) => (type ? "block" : "none")};
    }
`;
